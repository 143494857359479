import { useMemo, useEffect, useState } from 'react';
import { ApolloError } from 'apollo-client';

import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useUser from 'src/hooks/use-user';

import { useGetPersonalizedProductsQuery } from 'types/graphql';
import type { GqlProducts } from 'types/graphql';
import { Product, parseProduct } from 'src/utils/helpers/product';

export type UseCategoryRecommendedProductsParams = {
  personalizedProductsQuery: Pick<
    ReturnType<typeof useGetPersonalizedProductsQuery>,
    'data' | 'error' | 'loading' | 'refetch'
  > | null;
};

type UseCategoryRecommendedProductsReturn = {
  products: Product[];
  loading: boolean;
  error?: ApolloError;
};

export const useCategoryRecommendedProducts = ({
  personalizedProductsQuery,
}: UseCategoryRecommendedProductsParams): UseCategoryRecommendedProductsReturn => {
  const { data, loading, error, refetch } = personalizedProductsQuery ?? {
    data: null,
    loading: false,
    error: undefined,
  };
  const { dispensary } = useDispensary();
  const user = useUser();
  const [userLoggedIn, setUserLoggedIn] = useState<boolean>(user.isLoggedIn);
  const dispensaryId = dispensary?.id;

  const products = useMemo(
    () => data?.getPersonalizedProducts?.products.map((product: GqlProducts) => parseProduct(product)) ?? [],
    [data?.getPersonalizedProducts?.products]
  );

  useEffect(() => {
    if (dispensaryId && userLoggedIn !== user.isLoggedIn) {
      setUserLoggedIn(user.isLoggedIn);
      void refetch?.();
    }
  }, [refetch, userLoggedIn, user.isLoggedIn, dispensaryId]);

  return {
    products,
    loading,
    error,
  };
};
