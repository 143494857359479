import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { useAmplitude } from 'shared/hooks/use-amplitude';
import useProductQueryParams from 'hooks/use-product-query-params';
import useUI from 'hooks/use-ui';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useDispensarySpecials from 'src/dispensary/hooks/use-dispensary-specials';
import { useProductFiltersApplied } from 'hooks/use-product-filters-applied';
import { useMenuHeaderHeading } from 'components/menu-header/use-menu-header-heading';
import { specialIsActive } from 'shared/helpers/specials';

import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { tracker } from 'utils/analytics';
import {
  buildGTMNavigationBreadcrumbs,
  getItemListName,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { getPageType } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/get-page-type';
import { TwoColumn, TwoColumnContent } from 'components/layouts/two-column';
import { OneColumn, OneColumnContent } from 'components/layouts/one-column';
import { DesktopOnly, MobileOnly } from 'shared/components';
import MenuSidebar from 'components/menu-sidebar';
import EmptyLoadingState from 'components/empty-loading-state';
import ProductList from 'components/product-list';
import MenuHeader from 'components/menu-header';
import { MainContent } from 'components/skip-to-main';
import { BreadCrumbs } from 'src/components/bread-crumbs';

export default function Menu({ displaySidebar = true, specialId = undefined, specialType = undefined, ...props }) {
  const { loading, totalCount, dispensary, lastCrumbLabel } = props;
  const { queryParams } = useProductQueryParams();
  const UI = useUI();
  const amplitude = useAmplitude();
  const { route } = useRouter();
  const { isDutchieMain } = UI;
  const onSpecialsPage = _.includes(route, 'specials');

  if (!_.isEmpty(queryParams.search) && !loading) {
    amplitude.log(`Menu Search - Consumer`, {
      description: `User is inputting search terms on a dispensary menu`,
      menuVariant: UI.analyticsMenuVariant,
      searchTerm: queryParams.search,
      searchResult: totalCount ?? 0,
      dispensaryId: dispensary?.id,
      dispensaryName: dispensary?.name,
    });
  }

  return (
    <>
      <DesktopOnly>
        {isDutchieMain && <ExtraPadding />}
        <TwoColumn mx={20}>
          {isDutchieMain && !onSpecialsPage && (
            <BreadCrumbsContainer>
              <BreadCrumbs lastCrumbLabel={lastCrumbLabel} />
            </BreadCrumbsContainer>
          )}

          {displaySidebar && <MenuSidebar specialId={specialId} specialType={specialType} />}
          <TwoColumnContent>
            <Content {...props} />
          </TwoColumnContent>
        </TwoColumn>
      </DesktopOnly>

      <MobileOnly>
        <OneColumn showingBreadCrumbs={!!isDutchieMain}>
          <Container>
            <OneColumnContent>
              <Content {...props} />
            </OneColumnContent>
          </Container>
        </OneColumn>
      </MobileOnly>
    </>
  );
}

const Content = ({
  dispensary,
  products,
  loading,
  page,
  totalCount,
  totalPages,
  children,
  hideHeader = false,
  hideHeaderAndProductList = false,
  collection,
  hideFilters = false,
  heading,
  description,
  productsCarouselQueries,
}) => {
  const { setProductCount } = useUI();
  const category = useDispensaryCategory();
  const productFiltersApplied = useProductFiltersApplied();
  const router = useRouter();
  const { specialId } = router.query;
  let menuHeading = useMenuHeaderHeading({ category, page });
  menuHeading = heading ?? menuHeading;
  const { specials } = useDispensarySpecials({ specialId });
  const currentSpecial = _.find(specials, ({ _id }) => _id === specialId);
  const [isCurrentSpecialActive, setIsCurrentSpecialActive] = useState(true);

  const analyticsPage = getPageType(router);

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.menu,
  });

  useEffect(() => {
    tracker.setContext({
      activeNavigationBreadcrumbs: buildGTMNavigationBreadcrumbs({
        category,
        currentSpecial,
        menuHeading,
        pageType: analyticsPage,
      }),
    });
  }, [category, currentSpecial, menuHeading, analyticsPage]);

  useEffect(() => {
    setProductCount(totalCount);
  }, [setProductCount, totalCount]);

  useEffect(() => {
    if (currentSpecial) {
      setIsCurrentSpecialActive(specialIsActive(currentSpecial, dispensary?.timezone));
    }
  }, [currentSpecial, dispensary?.timezone]);

  const isEmpty = !loading && _.isEmpty(products);
  const showExpiredSpecial = !isCurrentSpecialActive;
  const hideProductFilters = hideFilters || isEmpty || showExpiredSpecial;

  return (
    <StyledMainContent>
      {children && children}
      {!hideHeaderAndProductList && (
        <>
          {!hideHeader && (
            <MenuHeader
              dispensary={dispensary}
              page={page}
              category={category}
              collection={collection?.label}
              hideFilters={hideProductFilters}
              showExpiredSpecial={showExpiredSpecial}
              heading={menuHeading}
              description={description}
              special={currentSpecial}
            />
          )}
          <EmptyLoadingState
            productFiltersApplied={productFiltersApplied}
            onCurrentSpecialPage={!!currentSpecial}
            isEmpty={isEmpty}
            isLoading={loading}
            page={page?.replace(/\s/g, '_')}
            delay={500}
            specialId={specialId}
            showExpiredSpecial={showExpiredSpecial}
            special={currentSpecial}
          >
            <ProductList
              products={products}
              productsCarouselQueries={productsCarouselQueries}
              totalCount={totalCount || products?.length || 0}
              totalPages={totalPages || 1}
              dispensary={dispensary}
              trackerSource={trackerSource}
              loading={loading}
              displaySpecial
              displayStaffPick
            />
          </EmptyLoadingState>
        </>
      )}
    </StyledMainContent>
  );
};

const StyledMainContent = styled(MainContent)`
  ${({ theme }) => theme.breakpoints.up(`md`)} {
    padding: 0;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 970px;
`;

const BreadCrumbsContainer = styled.div`
  position: absolute;
  top: 14px;
  left: -25px;
`;

const ExtraPadding = styled.div`
  padding-top: 12px;
`;
