import React from 'react';
import { useRouter } from 'next/router';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';

import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import useUI from 'hooks/use-ui';

import Sidebar from './sidebar';

function MenuSidebar({ specialId, specialType }) {
  const category = useDispensaryCategory();
  const { dispensary } = useDispensary();
  const { route } = useRouter();
  const { onSearchPage } = useUI();

  const {
    apolloValues: { loading },
    ...menuFilterOptions
  } = useMenuFilterOptions({ specialId, specialType });

  const noFilters = _.every(menuFilterOptions, (opt) => _.isEmpty(opt));
  if (noFilters && !loading) {
    return null;
  }

  return (
    <Sidebar
      menuFilterOptions={menuFilterOptions}
      category={category}
      isLoading={loading}
      route={route}
      dispensary={dispensary}
      onSearchPage={onSearchPage}
    />
  );
}

export default observer(MenuSidebar);
