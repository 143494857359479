import React from 'react';
import { observer } from 'mobx-react-lite';
import _ from 'lodash';
import styled from 'styled-components';
import { useObserver } from 'mobx-react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getProductsFilterCount } from 'utils/helpers/ui';
import useProductQueryParams from 'hooks/use-product-query-params';
import useMenuSortOptions from 'hooks/use-menu-sort-options';
import useUI from 'hooks/use-ui';

import ContentHeader from 'components/content-header';
import MenuLayoutSelector from 'components/menu-layout-selector';
import { MenuLayoutSelectorV2 } from 'src/components/menu-layout-selector-v2';
import MenuSort from 'components/menu-sort';
import { DrawerToggleButton } from 'components/mobile-filters-drawer';
import { DesktopOnly, MobileOnly } from 'shared/components';
import { useAmplitude } from 'shared/hooks/use-amplitude';
import { MenuSortV2 } from '../menu-sort-v2';
import { useMenuHeaderHeading } from './use-menu-header-heading';
import { MenuFiltersDrawer } from './menu-filters-drawer';

function MenuHeader({
  dispensary,
  category,
  page,
  collection,
  hideFilters,
  showExpiredSpecial = false,
  heading,
  description,
  special,
}) {
  const { queryParams, setQueryParams } = useProductQueryParams();
  const router = useRouter();
  const UI = useUI();
  const showMobileFilters = useObserver(() => UI.showMobileFilters);
  const onBrandsPage = _.includes(router?.route, 'brands');
  const filterCount = getProductsFilterCount({ queryParams, category, onBrandsPage });
  const offerSpecialPage = _.includes(router.route, 'offer');
  const saleSpecialPage = _.includes(router.route, 'sale');
  const flags = useFlags();
  const shouldDisableSearch = flags['ecomm.configuration.disable-product-search'] ?? false;
  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  let menuHeading = useMenuHeaderHeading({
    category,
    page,
    collection,
  });
  menuHeading = heading ?? menuHeading;

  // preload
  useMenuSortOptions();

  const amplitude = useAmplitude();
  function onSortChange(event) {
    const newSort = event.target.value;

    amplitude.log(`Sort By Filter`, {
      description: `User clicks/taps on Sort By Filter while shopping`,
      sortBy: newSort,
    });

    setQueryParams({
      sortby: newSort,
    });
  }

  return (
    <ContentHeader
      page={page}
      heading={menuHeading}
      description={description}
      searchString={shouldDisableSearch ? false : queryParams.search}
      dispensary={dispensary}
      isMobileEcommApp={UI.isMobileEcommApp}
      selectedSpecial={special}
      offerSpecialPage={offerSpecialPage}
      saleSpecialPage={saleSpecialPage}
      showExpiredSpecial={showExpiredSpecial}
    >
      <FiltersContainer>
        {!(hideFilters && !onBrandsPage) && (
          <DesktopOnly>
            {updatedFilterIconsEnabled ? (
              <MenuSortV2 />
            ) : (
              <MenuSort dispensary={dispensary} category={category} onChange={onSortChange} />
            )}
            {updatedFilterIconsEnabled ? (
              <LayoutSelectorContainer>
                <MenuLayoutSelectorV2 />
              </LayoutSelectorContainer>
            ) : (
              <MenuLayoutSelector />
            )}
          </DesktopOnly>
        )}
        {!hideFilters && (
          <MobileOnly>
            <DrawerToggleButton onClick={() => (UI.showMobileFilters = true)} filterCount={filterCount} />
            <MenuFiltersDrawer open={showMobileFilters} onClose={() => (UI.showMobileFilters = false)} />
          </MobileOnly>
        )}
      </FiltersContainer>
    </ContentHeader>
  );
}

export default observer(MenuHeader);

const FiltersContainer = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  margin-left: auto;
`;

const LayoutSelectorContainer = styled.div`
  margin-left: 24px;
`;
