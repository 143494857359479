import React from 'react';

import { Product } from 'src/utils/helpers/product';
import { CategoryRecommendationsCarousel } from './category-recommendations-carousel';

type LoggedOutProps = {
  products: Product[];
  loading: boolean;
};

export function LoggedOut({ products, loading }: LoggedOutProps): JSX.Element | null {
  const heading = 'Trending now';

  return <CategoryRecommendationsCarousel products={products} loading={loading} heading={heading} />;
}
