import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ClassNameMap } from '@material-ui/styles';
import MuiDrawer from '@material-ui/core/Drawer';

import { useColors } from 'contexts/colors';
import useTranslation from 'hooks/use-translation';

import FilterIcon from 'assets/filter-icon';
import { MobileFilterIcon } from 'src/assets/mobile-filter-icon';
import ExitIcon from 'assets/exit-icon-2';
import { Clickable } from 'shared/components';
import { Button, CollapsibleSection } from 'components/core';
import { heading3, heading5 } from 'components/core/typography';

type DrawerProps = {
  open: boolean;
  isEmbedded?: boolean;
  onClose: () => void;
  applyFilters: () => void;
  clearFilters: () => void;
  filterCount: number;
  classes?: ClassNameMap;
  children: ReactNode;
  anchor?: string;
};

export function Drawer({
  open,
  anchor = `top`,
  onClose,
  isEmbedded,
  applyFilters,
  clearFilters,
  filterCount,
  classes,
  children,
}: DrawerProps): JSX.Element {
  const colors = useColors();
  const { t } = useTranslation();
  const flags = useFlags();

  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  return (
    <StyledDrawer
      classes={classes}
      anchor={anchor}
      open={open}
      SlideProps={{ timeout: 200 }}
      aria-labelledby={t('menu.filtersDrawer', 'filters Drawer')}
      aria-describedby={t('menu.filtersDrawerDescription', 'menu filter controls')}
      onClose={onClose}
      _isEmbedded={isEmbedded}
    >
      <ContentWrapper>
        <HeaderWrapper>
          <HeaderContent>
            {updatedFilterIconsEnabled ? <MobileFilterIcon color='#a0a7ab' /> : <FilterIcon />}
            <Heading>{t('mobileFilters.filters', 'Filters')}</Heading>
            <CloseButton onClick={onClose}>
              <ExitIcon fill={colors.grey[75]} height='22px' />
            </CloseButton>
          </HeaderContent>
        </HeaderWrapper>

        <CollapsibleSection>
          <Button width='100%' onClick={applyFilters}>
            {t('mobileFilters.applyFilters', 'Apply Filters')}{' '}
            {filterCount > 0 && <FilterCount>({filterCount})</FilterCount>}
          </Button>
          <ClearAllButton onClick={clearFilters}>{t('mobileFilters.clearFilters', 'Clear all')}</ClearAllButton>
        </CollapsibleSection>

        {children}
      </ContentWrapper>
    </StyledDrawer>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const StyledDrawer = styled(({ _isEmbedded, open, ...props }) => <MuiDrawer open={open} {...props} />)`
  ${space}
  font-family: ${({ theme }) => theme.customized.fonts.secondary};

  .MuiBackdrop-root {
    background-color: ${({ theme, _isEmbedded }) => (_isEmbedded ? theme.colors.whiteOverlay : `transparent`)};
  }
`;

const ContentWrapper = styled.div`
  padding: 16px 24px;
`;

const Heading = styled.h3`
  ${heading3}
  margin: 0 auto 0 11px;
  color: ${({ theme }) => theme.colors.grey[25]};
`;

const CloseButton = styled(Clickable)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  margin-left: 14px;
  height: 42px;
  width: 42px;
`;

const HeaderContent = styled.div`
  display: flex;
  height: 32px;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  min-height: 45px;
`;

const FilterCount = styled.span`
  font-variant-ligatures: none;
`;

const ClearAllButton = styled(Clickable)`
  ${heading5}
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  display: inline-block;
  font-weight: bold;
  margin: 24px auto 0 auto;
  width: 5rem;
`;
