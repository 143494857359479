import React from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';

import { gqlFilterWeightToQueryParamValue } from 'utils/helpers/product/weight-filter-converters';
import { CategoriesWithPotencies } from 'shared/constants';
import { PotencySlider } from 'components/potency-slider';
import { Button, CollapsibleSection, CheckboxGroup } from 'components/core';
import { Drawer as MobileFiltersDrawer, Sort } from 'components/mobile-filters-drawer';

export function MenuFiltersDrawer({
  weightOptions,
  brandOptions,
  strainTypeOptions,
  sortOptions,
  effectOptions,
  selectedFilters,
  filterCount,
  onClose,
  applyFilters,
  clearFilters,
  updateFilter,
  open,
  category,
  onBrandsPage,
  hidePotencyFilters,
  isEmbedded,
  showMenuSortFilter,
}) {
  const { t } = useTranslation();

  return (
    <MobileFiltersDrawer
      open={open}
      onClose={onClose}
      applyFilters={applyFilters}
      clearFilters={clearFilters}
      filterCount={filterCount}
      isEmbedded={isEmbedded}
    >
      {/* WEIGHTS */}
      {weightOptions?.length > 1 && (
        <CollapsibleSection
          title={t('common.weightOptions', 'Weights')}
          description={t('common.filters.displayAvailability', 'Display availablity and pricing')}
        >
          <WeightsContainer>
            {_.map(weightOptions, (weight) => {
              const weightForQueryParams = gqlFilterWeightToQueryParamValue(weight);
              return (
                <WeightButton
                  key={weightForQueryParams}
                  selected={weightForQueryParams === selectedFilters.weight}
                  onClick={() => updateFilter(`weight`, weightForQueryParams)}
                >
                  {weight}
                </WeightButton>
              );
            })}
          </WeightsContainer>
        </CollapsibleSection>
      )}

      {/* SORT */}
      {showMenuSortFilter && (
        <CollapsibleSection title={t('common.sort', 'Sort')} collapsible>
          <Sort
            selectedOption={selectedFilters.sortby}
            onChange={({ target }) => updateFilter(`sortby`, target.value)}
            options={sortOptions}
          />
        </CollapsibleSection>
      )}

      {/* STRAIN TYPES */}
      {!_.isEmpty(strainTypeOptions) && (
        <CollapsibleSection title={t('common.strainTypeOptions', 'Strain Types')} collapsible>
          <CheckboxGroup
            options={strainTypeOptions}
            selectedOptions={selectedFilters.straintypes}
            onSelect={(value) => updateFilter(`straintypes`, value)}
          />
        </CollapsibleSection>
      )}

      {!_.isEmpty(effectOptions) && !onBrandsPage && (
        <CollapsibleSection title={t('common.effectOptions', 'Effects')} collapsible>
          <CheckboxGroup
            options={effectOptions}
            selectedOptions={selectedFilters.effects}
            onSelect={(value) => updateFilter(`effects`, value)}
          />
        </CollapsibleSection>
      )}

      {/* POTENCIES */}
      {!hidePotencyFilters && _.includes(CategoriesWithPotencies, category?.key) && (
        <CollapsibleSection title={t('common.potency', 'Potency')} collapsible>
          <PotencySlider
            title='THC'
            onChange={(_event, value) => updateFilter(`potencythc`, value)}
            value={selectedFilters.potencythc}
            category={category}
          />
          <PotencySlider
            title='CBD'
            onChange={(_event, value) => updateFilter(`potencycbd`, value)}
            value={selectedFilters.potencycbd}
            category={category}
          />
        </CollapsibleSection>
      )}

      {/* BRANDS */}
      {!_.isEmpty(brandOptions) && !onBrandsPage && (
        <CollapsibleSection title={t('common.brandOptions', 'Brands')} collapsible>
          <CheckboxGroup
            options={brandOptions}
            selectedOptions={selectedFilters.brands}
            onSelect={(value) => updateFilter(`brands`, value)}
          />
        </CollapsibleSection>
      )}
    </MobileFiltersDrawer>
  );
}

const WeightButton = styled(Button)`
  align-items: center;
  border-radius: 5px;
  display: flex;
  height: 47px;
  min-width: 58px;
  max-width: 58px;
  justify-content: center;
  margin: 0 4.5px;
  text-transform: lowercase;
  border: ${({ theme, selected }) => (selected ? `none` : `solid 1px ${theme.colors.blueGrey[80]}`)};
  color: ${({ theme, selected }) => (selected ? theme.colors.white : theme.colors.grey[25])};
  font-weight: ${({ selected }) => (selected ? `bold` : `normal`)};
  background-color: ${({ theme, selected }) => (selected ? theme.colors.grey[30] : theme.colors.white)};
`;

const WeightsContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 15px 0 0 -4.5px;
  overflow-x: auto;
  ::-webkit-scrollbar {
    display: none;
  }
`;
