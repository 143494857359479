import React, { useCallback } from 'react';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import useUI from 'src/hooks/use-ui';
import { useAddToCartModal } from 'components/modals/add-to-cart';
import { tracker } from 'src/utils/analytics';

import { SponsoredBrandCarouselV2 } from 'src/components/carousels/ads-carousels/sponsored-brand-carousel-v2';
import { CategoryPageRecommendations } from 'src/components/carousels/personalization/category-page-recommendations';
import ProductListItem from './product-list-item';
import { useGroupProductsByRows } from './use-group-products-by-rows';
import { getShowInterstitialCarousels } from './product-list.utils';

export default function ProductItemList({ products, dispensary, trackerSource, productsCarouselQueries }) {
  const { showSponsoredBrandsCarousel, showCategoryRecommendationsCarousel } = getShowInterstitialCarousels(
    productsCarouselQueries
  );
  const { sponsoredBrandQuery, personalizedProductsQuery } = productsCarouselQueries ?? {
    sponsoredBrandQuery: null,
    personalizedProductsQuery: null,
  };

  const UI = useUI();
  const { open: openAddToCartModal } = useAddToCartModal();

  const isDesktop = useMediaQuery((theme) => theme.breakpoints.up(`md`));

  const groupedProducts = useGroupProductsByRows({
    products,
    isListView: true,
    rows: showSponsoredBrandsCarousel ? [3, 5] : [0, 3],
  });

  const handleClick = useCallback(
    (product, index) => {
      tracker.setContext({
        activeProductPosition: index,
      });
      tracker.productClicked({ product, position: index, trackerSource });

      UI.activeProductSource = trackerSource;
      UI.setProductPosition({
        productId: product.id,
        productIndex: index,
      });
    },
    [UI, trackerSource]
  );

  const handleAddToCart = useCallback(
    (product, index, option) => {
      openAddToCartModal({
        quantity: 1,
        option,
        product,
        dispensary,
        trackerSource,
      });

      handleClick(product, index);
    },
    [dispensary, handleClick, openAddToCartModal, trackerSource]
  );

  const sharedProps = {
    onClick: handleClick,
    onAddToCart: handleAddToCart,
    isMobile: !isDesktop,
  };

  return (
    <div data-testid='product-list'>
      {groupedProducts[0].map((product, index) => (
        <ProductListItem key={product.id} product={product} productIndex={index} {...sharedProps} />
      ))}

      {showSponsoredBrandsCarousel && (
        <AdUnit data-testid='ad-unit-sponsored-brands-carousel'>
          <SponsoredBrandCarouselV2 sponsoredBrandQuery={sponsoredBrandQuery} />
        </AdUnit>
      )}

      {groupedProducts[1].map((product, index) => (
        <ProductListItem key={product.id} product={product} productIndex={index} {...sharedProps} />
      ))}

      {showCategoryRecommendationsCarousel && (
        <AdUnit data-testid='ad-unit-category-recommendations-carousel'>
          <CategoryPageRecommendations personalizedProductsQuery={personalizedProductsQuery} />
        </AdUnit>
      )}

      {groupedProducts[2].map((product, index) => (
        <ProductListItem key={product.id} product={product} productIndex={index} {...sharedProps} />
      ))}
    </div>
  );
}

const AdUnit = styled.aside`
  @media (min-width: 960px) and (max-width: 1240px) {
    width: calc(100vw - 270px);
  }

  &:last-child {
    border-top: 1px solid ${({ theme }) => theme.colors.blueGrey[90]};
    margin-top: 24px;
  }

  & > div {
    margin-top: 24px;
    padding: 0;
  }
`;
