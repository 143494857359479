import React from 'react';

function SvgCardsIcon(props) {
  const { color = '#788289' } = props;
  return (
    <svg width={12} height={12} {...props}>
      <path
        fill={color}
        d='M1.071 0h2.858C4.52 0 5 .48 5 1.071v2.858C5 4.52 4.52 5 3.929 5H1.071C.48 5 0 4.52 0 3.929V1.071C0 .48.48 0 1.071 0zm7 0h2.858C11.52 0 12 .48 12 1.071v2.858C12 4.52 11.52 5 10.929 5H8.071C7.48 5 7 4.52 7 3.929V1.071C7 .48 7.48 0 8.071 0zm-7 7h2.858C4.52 7 5 7.48 5 8.071v2.858C5 11.52 4.52 12 3.929 12H1.071C.48 12 0 11.52 0 10.929V8.071C0 7.48.48 7 1.071 7zm7 0h2.858C11.52 7 12 7.48 12 8.071v2.858C12 11.52 11.52 12 10.929 12H8.071C7.48 12 7 11.52 7 10.929V8.071C7 7.48 7.48 7 8.071 7z'
      />
    </svg>
  );
}

export default SvgCardsIcon;
