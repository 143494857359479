import React from 'react';
import styled from 'styled-components';

import { gqlFilterWeightToQueryParamValue } from 'utils/helpers/product/weight-filter-converters';
import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';

import Section from 'components/sidebar-filters/section';
import Weight from './weight';

export default function WeightFilter({ weights }) {
  const { queryParams } = useProductQueryParams();
  const currentWeight = queryParams.weight;
  const { t } = useTranslation();

  return (
    <Section addPaddingRight startsOpen heading={t('common.weight_plural', 'Weights')}>
      <Text>{t('common.weight-filter-header', 'Display availability')}</Text>
      <ButtonsContainer>
        {weights.map((weight) => {
          const weightForQueryParams = gqlFilterWeightToQueryParamValue(weight);
          return (
            <Weight
              key={weightForQueryParams}
              selected={weightForQueryParams === currentWeight}
              weightForQueryParams={weightForQueryParams}
              displayWeight={weight}
            />
          );
        })}
      </ButtonsContainer>
    </Section>
  );
}

const ButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 1.33;
  margin: 9px 0 0 0;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;
