import React from 'react';
import _ from 'lodash';

import { CategoriesWithPotencies } from 'shared/constants';
import EmptyLoadingState from 'components/empty-loading-state';
import { TwoColumnSidebar } from 'components/layouts/two-column';
import BrandsFilter from './brands-filter';
import PotencyFilter from './potency-filter';
import StrainTypeFilter from './strain-type-filter';
import SubcategoryFilter from './subcategory-filter';
import WeightFilter from './weight-filter';
import { CategorySubcategoryFilter } from './category-subcategory-filter';
import { EffectsFilter } from './effects-filter';

export default function Sidebar({ menuFilterOptions, category, isLoading, route, dispensary, onSearchPage }) {
  const { brands, categoriesSubcategories, subcategories, strainTypes, weights, effects } = menuFilterOptions;
  const onBrandsPage = _.includes(route, 'brands');
  const hidePotencyFilters = _.get(dispensary, `storeSettings.hideFilters`, false);
  const showSubcategories = !_.isEmpty(subcategories) && !onBrandsPage;
  const showWeights = weights?.length > 1 && !onBrandsPage;
  const showStrainTypes = !_.isEmpty(strainTypes) && !onBrandsPage;
  const showPotencies = !hidePotencyFilters && _.includes(CategoriesWithPotencies, category?.key) && !onBrandsPage;
  const showBrands = !_.isEmpty(brands);
  const showEffects = !_.isEmpty(effects) && !onBrandsPage && !_.get(dispensary, `storeSettings.hideEffects`, false);
  const onAllProductsPage = onSearchPage;

  return (
    <TwoColumnSidebar>
      <EmptyLoadingState isLoading={isLoading} page='filterSideBar' delay={500}>
        {onAllProductsPage && <CategorySubcategoryFilter categoriesSubcategories={categoriesSubcategories} />}
        {showSubcategories && !onAllProductsPage && (
          <SubcategoryFilter category={category} subcategories={subcategories} />
        )}
        {showWeights && <WeightFilter weights={weights} />}
        {showBrands && <BrandsFilter brands={brands} />}
        {showStrainTypes && <StrainTypeFilter strainTypes={strainTypes} />}
        {showPotencies && <PotencyFilter category={category} />}
        {showEffects && <EffectsFilter effects={effects} />}
      </EmptyLoadingState>
    </TwoColumnSidebar>
  );
}
