import React from 'react';
import styled from 'styled-components';
import { ListItem } from './list-item';

import { CategoryOption } from './types';

type SubcategoriesListProps = {
  subcategories: CategoryOption[];
  selectedSubcategories: string[];
  handleClickSubcategory: (subcategoryValue: string) => void;
};

export function SubcategoriesList(props: SubcategoriesListProps): JSX.Element {
  const { subcategories, selectedSubcategories, handleClickSubcategory } = props;
  const currentSubcategory = selectedSubcategories[0];

  return (
    <SubcategoriesContainer>
      {subcategories.map((subcategory: CategoryOption) => (
        <ListItem
          key={subcategory.key}
          label={subcategory.label}
          selected={subcategory.key === currentSubcategory}
          onClick={() => handleClickSubcategory(subcategory.key)}
          secondary
        />
      ))}
    </SubcategoriesContainer>
  );
}

const SubcategoriesContainer = styled.div`
  padding-left: 15px;
`;
