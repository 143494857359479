import _ from 'lodash';
import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { getPotencyStrings } from 'utils/helpers/product';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useTranslation from 'hooks/use-translation';
import useProductPrices from 'hooks/use-product-prices';
import useViewport from 'hooks/use-viewport';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';
import { getProductWeight, isOutOfStock } from 'shared/helpers/products';

import { SponsoredTag } from 'utils/ads/ui';
import { StaffPickChip, ComingSoonChip } from 'components/chips';
import ProductImage from 'components/product-image';
import WeightTile from 'components/weight-tile';
import Link from 'components/core/link';
import SpecialOfferChip from 'components/core/special-offers-chip';
import { useRouter } from 'next/router';
import {
  getSpecialBadgeType,
  formatDiscountForDisplay,
  isNotSolitaryMinimumSpendOffer,
  productIsPartOfActiveSpecial,
  productSatisfiesSaleWeight,
} from 'shared/helpers/specials';
import useCart from 'hooks/use-cart';
import { useObserver } from 'mobx-react-lite';

import { OutOfStock } from './out-of-stock';

const ProductListItem = forwardRef((props, ref) => {
  const {
    product,
    hasStrainType,
    parsedOptions,
    weightedProduct,
    onAddToCart,
    onProductClick,
    showTAC,
    hasBeenVisible,
    addButtonEnabled = false,
    ...restProps
  } = props;
  const category = useDispensaryCategory();
  const { query: routerQuery } = useRouter();
  const UI = useUI();
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const { href, route } = usePaths({ category, product });
  const { t } = useTranslation();
  const { width } = useViewport();
  const { brand, featured, specialData } = product;
  const isSmallWidth = useMediaQuery((theme) => theme.breakpoints.only(`sm`));
  const isFeatured = featured?.current;
  const isInBogo = productIsPartOfActiveSpecial(product, menuType);

  const { weightedSpecialPrices } = useProductPrices(product);

  const { THC: THCString, CBD: CBDString, TAC: TACString } = getPotencyStrings(product);
  const potencySeparator = '\u00A0\u00A0|\u00A0\u00A0';

  const badgeType = getSpecialBadgeType(product, product?.specialData?.saleSpecials, product?.Options);
  const showWeightSpecials = badgeType !== `none`;
  // we don't want to show the special offer chip on the individual offers page
  const showSpecialOfferChip =
    !routerQuery?.specialId && isInBogo && isNotSolitaryMinimumSpendOffer(specialData.bogoSpecials);

  const initialPrice = _.find(weightedSpecialPrices, [`weight`, getProductWeight(parsedOptions[0]?.value)]);

  const comingSoonProductsFlagEnabled = useDispensaryFlag(`rollout.coming-soon-products`, product.DispensaryID);
  const isComingSoon = comingSoonProductsFlagEnabled && product.comingSoon;
  const disabled = UI.viewOnlyMode || isComingSoon;
  const showOutOfStockUIFlagEnabled = useDispensaryFlag(
    `ecomm.menu.show-out-of-stock-ui.rollout`,
    product.DispensaryID
  );

  // if the product has no quantity available or no options, we want to show the Out of order message
  const showOutOfStock = showOutOfStockUIFlagEnabled && isOutOfStock(product, parsedOptions);

  const getDiscountLabel = (priceData, weight) => {
    if (
      showWeightSpecials &&
      product?.specialData?.saleSpecials &&
      productSatisfiesSaleWeight(product, product.specialData.saleSpecials, weight)
    ) {
      return formatDiscountForDisplay(priceData.standardPrice, priceData.price, product, `sale`, weight);
    }
    return null;
  };

  const getAddToCartHandler = (option) => (event) => {
    // prevent firing encompassing link
    event.preventDefault();
    event.stopPropagation();
    onAddToCart(option);
  };

  const isSponsored = !!product.adTrackers;

  return (
    <Container ref={ref} hideBorderBottomOnLastItem={UI.isDutchieMain} isVisible={hasBeenVisible} {...restProps}>
      {hasBeenVisible && (
        <>
          <Link href={href} route={route}>
            {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid, styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions */}
            <ProductInfoContainer onClick={onProductClick}>
              <ProductDetails>
                {(isFeatured || isComingSoon) && (
                  <BadgeContainer>
                    {isFeatured && (
                      <ChipContainer mr='5px'>
                        <StaffPickChip />
                      </ChipContainer>
                    )}
                    {isComingSoon && (
                      <ChipContainer>
                        <ComingSoonChip />
                      </ChipContainer>
                    )}
                  </BadgeContainer>
                )}

                {showSpecialOfferChip && (
                  <BadgeContainer>
                    <ChipContainer>
                      <SpecialOfferChip />
                    </ChipContainer>
                  </BadgeContainer>
                )}

                {isSponsored && <SponsoredTag isSponsored={isSponsored} />}

                <ProductName>{product.name}</ProductName>

                {!!brand?.name && <Brand>{brand.name}</Brand>}

                <DetailsContainer>
                  {hasStrainType && (
                    <>
                      {product.strainType}
                      {(THCString || CBDString || TACString) && <>&nbsp;&bull;&nbsp;</>}
                    </>
                  )}

                  {showTAC && TACString && (
                    <>
                      {t('product-list-item.tac', 'TAC: {{ TACString }}', { TACString })}
                      {(THCString || CBDString) && potencySeparator}
                    </>
                  )}

                  {THCString && t('product-list-item.thc', 'THC: {{ THCString }}', { THCString })}

                  {THCString && CBDString && potencySeparator}

                  {CBDString && t('product-list-item.cbd', 'CBD: {{ CBDString }}', { CBDString })}
                </DetailsContainer>
                {parsedOptions.length === 1 && (
                  <WeightTile
                    mt={15}
                    onClick={getAddToCartHandler(parsedOptions[0].value)}
                    singleTile={parsedOptions.length <= 1}
                    variant={weightedProduct ? `weighted` : `non-weighted`}
                    discountLabel={getDiscountLabel(initialPrice, parsedOptions[0].value)} // this should always use a pill
                    standardPrice={initialPrice?.standardPrice}
                    disabled={disabled}
                    addButtonEnabled={addButtonEnabled}
                    {...parsedOptions[0]}
                  />
                )}
                {isSmallWidth && parsedOptions.length > 1 && (
                  <MultipleOptionsContainer width={width} addButtonEnabled={addButtonEnabled}>
                    {_.map(parsedOptions, (option) => {
                      const weight = getProductWeight(option.value);
                      const price = _.find(weightedSpecialPrices, [`weight`, weight]);

                      return (
                        <div key={option.label}>
                          <WeightTile
                            onClick={getAddToCartHandler(option.value)}
                            singleTile={parsedOptions.length <= 1}
                            variant={weightedProduct ? `weighted` : `non-weighted`}
                            discountLabel={getDiscountLabel(price, option.value)}
                            standardPrice={price.standardPrice}
                            disabled={disabled}
                            addButtonEnabled={addButtonEnabled}
                            {...option}
                          />
                        </div>
                      );
                    })}
                  </MultipleOptionsContainer>
                )}
              </ProductDetails>
              <StyledProductImage product={product} width={85} height={85} singleOption={parsedOptions.length <= 1} />
            </ProductInfoContainer>
          </Link>

          {!isSmallWidth && parsedOptions.length > 1 && (
            <MultipleOptionsContainer width={width} addButtonEnabled={addButtonEnabled}>
              {_.map(parsedOptions, (option) => {
                const weight = getProductWeight(option.value);
                const price = _.find(weightedSpecialPrices, [`weight`, weight]);
                return (
                  <div key={option.label}>
                    <WeightTile
                      discountLabel={getDiscountLabel(price, option.value)}
                      onClick={getAddToCartHandler(option.value)}
                      singleTile={parsedOptions.length <= 1}
                      variant={weightedProduct ? `weighted` : `non-weighted`}
                      standardPrice={price.standardPrice}
                      disabled={disabled}
                      addButtonEnabled={addButtonEnabled}
                      {...option}
                    />
                  </div>
                );
              })}
            </MultipleOptionsContainer>
          )}

          {showOutOfStock && <OutOfStock />}
        </>
      )}
    </Container>
  );
});

export default ProductListItem;

const Container = styled.div`
  padding: 30px 0 30px 0;
  display: flex;
  flex-direction: column;
  min-height: 150px;

  border-bottom: ${({ theme, isVisible }) => (isVisible ? `1px solid ${theme.colors.blueGrey[90]}` : `none`)};

  ${({ hideBorderBottomOnLastItem }) =>
    hideBorderBottomOnLastItem &&
    css`
      :last-of-type {
        padding-bottom: 0;
        border-bottom: none;
      }
    `}
`;

const DetailsContainer = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.grey[60]};
  display: flex;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 11px;
  font-weight: bold;
  line-height: 16px;
`;

const MultipleOptionsContainer = styled.div`
  display: flex;
  overflow-x: auto;
  margin: 15px -25px -6px -25px;
  padding: 3px 0 6px 25px;
  padding-top: ${({ addButtonEnabled }) => (addButtonEnabled ? `8px` : `3px`)};
  max-width: ${({ width }) => width}px;
  ::-webkit-scrollbar {
    display: none;
  }
  > :first-child > div {
    margin-left: 0;
  }
  > :last-child > div {
    margin-right: 25px;
  }
`;

const Brand = styled.span`
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 4px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 2px;
`;

const ProductInfoContainer = styled.a`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`;

const ProductName = styled.span`
  line-height: 19px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin-bottom: 5px;
`;

const StyledProductImage = styled(ProductImage)`
  border-radius: 3px;
  margin: ${({ singleOption }) => (!singleOption ? `-14px 5px 0 15px` : `0 5px 0 15px`)};
`;

const ChipContainer = styled.div`
  ${space}
`;

const BadgeContainer = styled.div`
  display: flex;
  padding-bottom: 11px;
`;
