import React, { useState } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import _ from 'lodash';

import useTranslation from 'hooks/use-translation';
import useProductQueryParams from 'hooks/use-product-query-params';

import { SidebarFiltersSection, SidebarFiltersLinkList, SidebarFiltersLinkListItem } from 'components/sidebar-filters';
import { Clickable } from 'shared/components';

export default function SubcategoriesFilter({ category, subcategories }) {
  const { query, route } = useRouter();
  const { defaults, queryParams } = useProductQueryParams();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();

  const onSpecialsPage = _.includes(route, 'specials');

  const currentSubcategories = query.subcategory ?? queryParams.subcategories[0];
  const firstSix = _.slice(subcategories, 0, 6);

  const heading = t('sidebar-filters.subcategories-header', {
    defaultValue: 'All {{category}}',
    category: !category || category?.key === `all` ? `Subcategories` : category.label,
  });

  return (
    <SidebarFiltersSection collapsible={false} heading={t('common.subcategory_plural', 'Subcategories')}>
      <SidebarFiltersLinkList>
        <SidebarFiltersLinkListItem
          category={category}
          label={heading}
          selected={_.isEmpty(currentSubcategories)}
          queryParam='subcategories'
          value={defaults.subcategories}
        />
        {_.map(expanded ? subcategories : firstSix, (subcategory) => (
          <SidebarFiltersLinkListItem
            category={category}
            key={subcategory.key}
            label={subcategory.label}
            selected={currentSubcategories === subcategory.value}
            subcategory={subcategory.value}
            queryParam='subcategories'
            value={onSpecialsPage ? subcategory.value : defaults.subcategories}
          />
        ))}
        {subcategories.length > 6 && (
          <ListItem onClick={() => setExpanded(!expanded)}>
            {expanded ? t('common.view-less', 'View Less') : t('common.view-more', 'View More')}
          </ListItem>
        )}
      </SidebarFiltersLinkList>
    </SidebarFiltersSection>
  );
}

const ListItem = styled(Clickable)`
  color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  font-size: 13px;
  line-height: 2.31;
  padding: 0;
  cursor: pointer;
`;
