import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { StyledTheme } from 'theme/styled-theme-type';

import useTranslation from 'hooks/use-translation';

import { Option, Select } from 'components/core';

type OptionType = {
  key: string;
  value: string;
  label: string;
};

type SortProps = {
  options: OptionType[];
  selectedOption: string;
  onChange: (event: ChangeEvent) => void;
};

const MenuProps = {
  anchorOrigin: {
    vertical: 'center',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
};

export function Sort({ onChange, options, selectedOption }: SortProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <StyledSelect onChange={onChange} value={selectedOption} MenuProps={MenuProps}>
      <Option value='default' key='default' disabled>
        {t('common.sortBy', 'Sort By')}
      </Option>
      {options.map((option) => (
        <Option key={option.key} value={option.value || option.key}>
          {option.label}
        </Option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  height: 51px;
  border-radius: 5px !important;
  border: ${({ theme }: { theme: StyledTheme }) => `1px solid ${theme.colors.grey[80]} !important`};
  background: ${({ theme }) => theme.colors.grey[100]};
  margin-top: 13px;
  fieldset {
    display: none;
  }
  .MuiSelect-root {
    justify-content: flex-start;
    font-size: 14px;
    color: ${({ theme }) => theme.colors.grey[35]};
  }
`;
