/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback } from 'react';
import { useRouter } from 'next/router';
import _ from 'lodash';

import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import useProductQueryParams from 'hooks/use-product-query-params';

import { ParsedUrlQuery } from 'querystring';
import { Brands } from './brands-filter.types';

type ParsedBrand = {
  key: string;
  label: string;
  value: string;
};

type ParsedBrands = ParsedBrand[];

type BrandsFilterReturn = {
  brandsFromQueryParams: string[];
  defaults: any;
  handleBrandSelection: (brand: string) => void;
  href: string;
  onBrandsPage: boolean;
  parsedBrands: ParsedBrands;
  query: ParsedUrlQuery;
  route: string;
};

export function useBrandsFilter(brands: Brands): BrandsFilterReturn {
  const { query } = useRouter();
  const { href, route } = usePaths({ brands: true });
  const { defaults, queryParams, setQueryParams } = useProductQueryParams();

  const { onBrandsPage } = useUI();

  const brandsFromQueryParams = queryParams.brands;

  const consolidatedBrands = _.uniqBy(brands, `name`);
  const parsedBrands = consolidatedBrands.map(({ id, name }) => ({ key: id, value: _.kebabCase(name), label: name }));

  const handleBrandSelection = useCallback(
    async (brand: string): Promise<void> => {
      // If brandsInQuery contains brand, remove it
      // If brandsInQuery doesn't contain brand, add it
      const newBrands = _.xor(brandsFromQueryParams, [brand]);

      setQueryParams({
        brands: newBrands,
        page: defaults.page,
      });
    },
    [brandsFromQueryParams, defaults.page, setQueryParams]
  );

  return {
    brandsFromQueryParams,
    defaults,
    handleBrandSelection,
    href,
    onBrandsPage,
    parsedBrands,
    query,
    route,
  };
}
