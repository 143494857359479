import styled from 'styled-components';
import React from 'react';
import { useRouter } from 'next/router';
import _ from 'lodash';

import { useAmplitude } from 'shared/hooks/use-amplitude';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import usePaths from 'hooks/use-paths';
import useProductQueryParams from 'hooks/use-product-query-params';
import useUI from 'hooks/use-ui';

import { Link } from 'components/core';

export function ListItem(props) {
  const {
    category,
    id = null,
    label,
    title,
    queryParam,
    selected,
    subcategory = null,
    value,
    onClick,
    href: hrefFromProps,
    route: routeFromProps,
  } = props;
  const router = useRouter();
  const amplitude = useAmplitude();
  const { dispensary } = useDispensary();
  const { onSearchPage } = useUI();

  const { href, route } = usePaths({
    category,
    subcategory,
    search: onSearchPage,
    brands: _.includes(router.route, 'brands'),
    specials: _.includes(router.route, 'specials'),
    id,
  });

  const { generateQueryParams } = useProductQueryParams();
  const newQuery = { ...generateQueryParams(queryParam, value) };
  delete newQuery.page;

  function handleClick() {
    if (onClick) {
      onClick();
    }

    if (props.queryParam === 'subcategories') {
      amplitude.log(`Subcategory Filter`, {
        description: `The user applies the subcategory filter option within a category page`,
        subcategorySelected: label,
        dispensaryId: dispensary?.id,
        dispensaryName: dispensary?.name,
      });
    } else if (props.queryParam === 'brands') {
      amplitude.log(`Brand Filter`, {
        description: `The user applies the brand filter option within a category page`,
        brandSelected: label,
        dispensaryId: dispensary?.id,
        dispensaryName: dispensary?.name,
      });
    }
  }

  return (
    <ListItemContainer>
      <Link href={hrefFromProps ?? href} route={routeFromProps ?? route} query={newQuery} scroll={false}>
        <Anchor title={title} selected={selected} onClick={handleClick}>
          {label}
        </Anchor>
      </Link>
    </ListItemContainer>
  );
}

export const LinkList = styled.ol`
  list-style: none;
  margin: 11px 0 0 0;
  padding: 0;
`;

export const ListItemContainer = styled.li`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Anchor = styled.a`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  font-weight: ${(props) => (props.selected ? `bold` : `normal`)};
  line-height: 2.31;
  display: flex;
  width: 100%;
`;
