import React from 'react';
import { useObserver } from 'mobx-react-lite';
import { useFlags } from 'launchdarkly-react-client-sdk';

import useUser from 'src/hooks/use-user';
import { useIsMobile } from 'src/hooks/use-is-mobile';

import {
  useCategoryRecommendedProducts,
  UseCategoryRecommendedProductsParams,
} from './use-category-recommended-products';
import { LoggedIn } from './logged-in';
import { LoggedOut } from './logged-out';

type CategoryRecommendationsProps = UseCategoryRecommendedProductsParams;

export function CategoryPageRecommendations({
  personalizedProductsQuery,
}: CategoryRecommendationsProps): JSX.Element | null {
  const flags = useFlags();
  const user = useUser();
  const isUserLoading = useObserver(() => user.loading);
  const isLoggedIn = useObserver(() => user.isLoggedIn);
  const isMobile = useIsMobile();
  const { products, loading, error } = useCategoryRecommendedProducts({ personalizedProductsQuery });

  const minimumProductCount = isMobile ? 2 : 5;
  const lessThanMinimumProducts = products.length < minimumProductCount;
  const categoryPageRecommendedProductsEnabled =
    flags['growth.ads.personalization.category-page-recommended-products-carousel.rollout'];

  if (
    isUserLoading ||
    lessThanMinimumProducts ||
    !products.length ||
    error ||
    !categoryPageRecommendedProductsEnabled
  ) {
    return null;
  }

  if (!isLoggedIn) {
    return (
      <div data-testid='logged-out-category-recommendations-carousel'>
        <LoggedOut products={products} loading={loading} />
      </div>
    );
  }

  return (
    <div data-testid='logged-in-category-recommendations-carousel'>
      <LoggedIn products={products} loading={loading} />
    </div>
  );
}
