import React from 'react';
import styled from 'styled-components';

import { HybridCarousel, HybridCarouselCard } from 'src/components/hybrid-carousel';
import { CarouselContainer } from 'src/components/hybrid-carousel/hybrid-carousel.styles';
import { Product } from 'src/utils/helpers/product';

import { useCategoryRecommendationsCarousel } from './use-category-recommendations-carousel';

type CategoryRecommendationsCarouselProps = {
  products: Product[];
  loading: boolean;
  heading: string;
  subheading?: string;
  icon?: React.ReactNode;
};

export const CategoryRecommendationsCarousel = React.forwardRef(
  ({ products, loading, heading, subheading, icon }: CategoryRecommendationsCarouselProps, ref): JSX.Element | null => {
    const {
      theme,
      dimensions,
      viewportVisibility,
      handleProductClick,
      handleQuickAddToCart,
    } = useCategoryRecommendationsCarousel();

    return (
      <StyledCarouselContainer {...theme} data-testid='category-recommendations-carousel' border ref={ref}>
        <HybridCarousel
          icon={icon}
          subheading={subheading}
          heading={heading}
          loading={loading}
          viewportVisibility={viewportVisibility}
          carouselTheme={theme}
          dimensions={dimensions}
        >
          {products.map((product: Product, index) => (
            <HybridCarouselCard
              carouselTheme={theme}
              dimensions={dimensions}
              filledQuickAdd={false}
              key={product.id}
              product={product}
              productIndex={index}
              onClick={() => handleProductClick(product, index)}
              onQuickAddClick={() => handleQuickAddToCart(product, index)}
            />
          ))}
        </HybridCarousel>
      </StyledCarouselContainer>
    );
  }
);

const StyledCarouselContainer = styled(CarouselContainer)`
  button[class^='circle-button'] {
    top: 10px;
  }
`;
