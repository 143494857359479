import _ from 'lodash';
import React from 'react';
import { useAmplitude } from 'shared/hooks/use-amplitude';

import { CheckboxGroup } from 'components/core';
import Section from 'components/sidebar-filters/section';
import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';

export default function StrainTypeFilter({ strainTypes }) {
  const { defaults, queryParams, setQueryParams } = useProductQueryParams();
  const currentStrainTypes = queryParams.straintypes;

  const amplitude = useAmplitude();

  const { t } = useTranslation();

  function handleOptionClick(strainType) {
    // If currentStrainTypes contains strainType, remove it
    // If currentStrainTypes doesn't contain strainType, add it
    const newArray = _.xor(currentStrainTypes, [strainType]);

    amplitude.log(`Strain Types Filter`, {
      description: `User clicks/taps on Strain Types Filter while shopping`,
      strainTypesSelected: newArray.join(', '),
    });

    setQueryParams({
      straintypes: newArray,
      page: defaults.page,
    });
  }

  return (
    <Section startsOpen heading={t('common.type_plural', 'Types')}>
      <CheckboxGroup options={strainTypes} selectedOptions={currentStrainTypes} onSelect={handleOptionClick} />
    </Section>
  );
}
