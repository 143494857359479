import { useCallback } from 'react';
import { useTheme } from 'styled-components';

import useUI from 'src/hooks/use-ui';
import { tracker } from 'src/utils/analytics';
import { useCardDimensions } from 'src/components/product-card/product-card.utils';
import { Dimensions } from 'src/components/product-card/product-card.constants';

import { useQuickAddToCart } from 'src/components/product-carousel/use-quick-add-to-cart';
import {
  ProductRecommendationSection,
  ProductRecommendationSource,
} from 'src/utils/analytics/trackers/internal-gtm-tracker/internal-gtm-tracker.types';
import { getItemListName } from 'src/utils/analytics/trackers/internal-gtm-tracker/helpers/item-list-name';
import { useRouter } from 'next/router';
import useViewportVisibility, { ViewportVisibility } from 'src/hooks/use-viewport-visibility';

import { Product } from 'src/utils/helpers/product';

type Theme = {
  backgroundColor: string;
  primaryColor: string;
};

type UseCategoryRecommendationsCarouselReturn = {
  handleProductClick: (product: Product, index: number) => void;
  handleQuickAddToCart: (product: Product, index: number) => void;
  theme: Theme;
  viewportVisibility: ViewportVisibility;
  dimensions: Dimensions;
};

export const useCategoryRecommendationsCarousel = (): UseCategoryRecommendationsCarouselReturn => {
  const UI = useUI();
  const router = useRouter();
  const { handleAddToCart } = useQuickAddToCart();

  const { customized } = useTheme();
  const viewportVisibility = useViewportVisibility();
  const dimensions = useCardDimensions({ gutter: 12 });

  const theme = {
    backgroundColor: `#f5f6f7`,
    primaryColor: customized.colors.buttonsLinks,
  };

  const trackerSource = getItemListName({
    router,
    source: ProductRecommendationSource.native,
    section: ProductRecommendationSection.personalizedRecommendations,
  });

  // Personalized Product card click
  const handleProductClick = useCallback(
    (product, index): void => {
      tracker.setContext({
        activeProductPosition: index,
      });

      tracker.productClicked({ product, position: index, trackerSource });

      UI.activeProductSource = trackerSource;
    },
    [UI, trackerSource]
  );

  const handleQuickAddToCart = useCallback(
    (product): void => {
      void handleAddToCart(product, trackerSource);
    },
    [handleAddToCart, trackerSource]
  );

  return {
    handleProductClick,
    handleQuickAddToCart,
    theme,
    viewportVisibility,
    dimensions,
  };
};
