import React from 'react';
import styled, { css } from 'styled-components';

import { usePagination } from '@material-ui/lab/Pagination';

import ChevronIcon from 'assets/chevron-icon';
import useTranslation from 'src/hooks/use-translation';
import { DesktopOnly } from 'src/../shared/components';

export default function PaginationControls({ numPages, currentPage, onPageSelect }) {
  const { items } = usePagination({
    count: numPages,
    page: currentPage || 1,
    onChange: (e, page) => onPageSelect(page),
  });

  const { t } = useTranslation();

  return (
    <StyledNav aria-label={t(`navigation.paginationNavigation`, `pagination navigation`)}>
      {items.map(({ page, type, ...item }) => {
        if (type === 'start-ellipsis' || type === 'end-ellipsis') {
          return (
            // Don't show on mobile so the component will fit inside the screen
            <DesktopOnly mediaQuery='sm' key={type}>
              <Ellipsis>…</Ellipsis>
            </DesktopOnly>
          );
        }

        if (type === 'next' || type === 'previous') {
          return (
            <NavButton
              {...item}
              type='button'
              key={type}
              aria-label={t(`navigation.goToPage`, { defaultValue: 'go to {{type}} page', type })}
              isPreviousButton={type === 'previous'}
            >
              <StyledChevIcon />
              {t(`navigation.goToPage`, { defaultValue: '{{type}}', type })}
            </NavButton>
          );
        }

        return (
          // Don't show on mobile so the component will fit inside the screen
          <DesktopOnly mediaQuery='sm' key={page}>
            <PageButton
              type='button'
              aria-label={t('navigation.goToPage', { defaultValue: 'go to page {{page}}', page })}
              {...item}
            >
              {page}
            </PageButton>
          </DesktopOnly>
        );
      })}
    </StyledNav>
  );
}

// SHARED BUTTON STYLES //

const disabledStyles = css`
  color: ${({ theme }) => theme.colors.blueGrey[80]};
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.grey[70]};
  cursor: default;
  font-weight: normal;
  svg {
    fill: ${({ theme }) => theme.colors.blueGrey[80]};
  }
`;

const selectedStyles = css`
  background: ${({ theme }) => theme.colors.grey[35]};
  color: ${({ theme }) => theme.colors.white};
  font-weight: bold;
  border: 1px solid ${({ theme }) => theme.colors.primaryBlack};
  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const commonButtonStyles = css`
  height: 28px;
  border: 1px solid ${({ theme }) => theme.colors.grey[70]};
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    cursor: pointer;
    ${selectedStyles}
    ${({ disabled }) => disabled && disabledStyles}
  }
`;

// PAGE NUMBER BUTTON STYLES //

const PageButton = styled.button`
  border-radius: ${({ theme }) => theme.customized.radius.sm};
  color: ${({ theme }) => theme.colors.primaryBlack};
  margin: 0 5px;
  min-width: 28px;
  text-align: center;

  ${commonButtonStyles}
  ${({ selected }) => selected && selectedStyles}
`;

// PREVIOUS/NEXT BUTTON STYLES //

const previousButtonStyles = css`
  width: 90px;
  padding: 4px 10px;
  svg {
    margin-right: 5px;
    order: 0;
    transform: rotate(90deg);
  }
`;

const nextButtonStyles = css`
  width: 74px;
  padding: 0 11px 0 16px;
  svg {
    margin-left: 5px;
    order: 1;
    transform: rotate(-90deg);
  }
`;

const NavButton = styled.button`
  align-items: center;
  border-radius: ${({ theme }) => theme.customized.radius.buttons};
  color: ${({ theme }) => theme.colors.primaryBlack};
  display: flex;
  margin: 0 12px;
  text-transform: capitalize;

  ${commonButtonStyles}
  ${({ isPreviousButton }) => (isPreviousButton ? previousButtonStyles : nextButtonStyles)}
  ${({ disabled }) => disabled && disabledStyles}

  svg {
    fill: ${({ theme }) => theme.colors.primaryBlack};
  }
`;

const StyledChevIcon = styled(ChevronIcon)`
  height: 7px;
  fill: ${({ theme }) => theme.colors.grey[35]};
`;

//  MISC COMPONENTS //

const StyledNav = styled.nav`
  font-size: 13px;
  color: ${({ theme }) => theme.colors.primaryBlack};
  list-style: none;
  display: flex;
  padding: 0;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    /* justify-content: space-between; */
  }
`;

const Ellipsis = styled.span`
  margin: auto 8px 0;
  display: flex;
  align-items: flex-end;
  height: 100%;
`;
