import React from 'react';
import styled from 'styled-components';

import { ListItem } from './list-item';
import { SubcategoriesList } from './subcategories-list';
import { CategorySubcategories } from './types';

type CategoriesListProps = {
  categoriesSubcategories: CategorySubcategories[] | null;
  selectedCategory: string | null;
  selectedSubcategories: string[];
  handleClickCategory: (categoryValue: string | null) => void;
  handleClickSubcategory: (subcategoryValue: string | null) => void;
};

export function CategoriesList(props: CategoriesListProps): JSX.Element {
  const {
    categoriesSubcategories,
    selectedCategory,
    selectedSubcategories,
    handleClickCategory,
    handleClickSubcategory,
  } = props;

  return (
    <LinkList>
      <ListItem label='All Categories' selected={!selectedCategory} onClick={() => handleClickCategory(null)} />
      <>
        {categoriesSubcategories?.map((categorySubcategory: CategorySubcategories) => {
          const {
            category: { key, label },
            subcategories,
          } = categorySubcategory;
          const currentCategorySelected = selectedCategory === key;

          return (
            <div key={key}>
              <ListItem
                key={key}
                label={label}
                selected={currentCategorySelected}
                onClick={() => handleClickCategory(key)}
              />
              {currentCategorySelected && (
                <SubcategoriesList
                  subcategories={subcategories}
                  selectedSubcategories={selectedSubcategories}
                  handleClickSubcategory={handleClickSubcategory}
                />
              )}
            </div>
          );
        })}
      </>
    </LinkList>
  );
}

const LinkList = styled.ol`
  list-style: none;
  margin: 11px 0 0 0;
  padding: 0;
`;
