import React, { SVGProps } from 'react';

type MobileFilterIconProps = SVGProps<SVGSVGElement> & {
  width?: string;
  height?: string;
};

export function MobileFilterIcon({ width = '24', height = '24', ...props }: MobileFilterIconProps): JSX.Element {
  return (
    <svg width={width} height={height} viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.28564 7.71429C4.28564 7.2409 4.6694 6.85715 5.14279 6.85715H13.7142C14.1876 6.85715 14.5714 7.2409 14.5714 7.71429C14.5714 8.18768 14.1876 8.57143 13.7142 8.57143H5.14279C4.6694 8.57143 4.28564 8.18768 4.28564 7.71429Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M16.2857 5.99999C15.339 5.99999 14.5715 6.7675 14.5715 7.71428C14.5715 8.66105 15.339 9.42856 16.2857 9.42856C17.2325 9.42856 18 8.66105 18 7.71428C18 6.7675 17.2325 5.99999 16.2857 5.99999ZM12.8572 7.71428C12.8572 5.82073 14.3922 4.28571 16.2857 4.28571C18.1793 4.28571 19.7143 5.82073 19.7143 7.71428C19.7143 9.60782 18.1793 11.1428 16.2857 11.1428C14.3922 11.1428 12.8572 9.60782 12.8572 7.71428Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12.8572 16.2857C12.8572 15.8123 13.2409 15.4286 13.7143 15.4286H18.8572C19.3306 15.4286 19.7143 15.8123 19.7143 16.2857C19.7143 16.7591 19.3306 17.1428 18.8572 17.1428H13.7143C13.2409 17.1428 12.8572 16.7591 12.8572 16.2857Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M4.28564 16.2857C4.28564 15.8123 4.6694 15.4286 5.14279 15.4286H8.57136C9.04475 15.4286 9.4285 15.8123 9.4285 16.2857C9.4285 16.7591 9.04475 17.1428 8.57136 17.1428H5.14279C4.6694 17.1428 4.28564 16.7591 4.28564 16.2857Z'
        fill='currentColor'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.1429 14.5714C10.1962 14.5714 9.42864 15.3389 9.42864 16.2857C9.42864 17.2325 10.1962 18 11.1429 18C12.0897 18 12.8572 17.2325 12.8572 16.2857C12.8572 15.3389 12.0897 14.5714 11.1429 14.5714ZM7.71436 16.2857C7.71436 14.3922 9.24938 12.8571 11.1429 12.8571C13.0365 12.8571 14.5715 14.3922 14.5715 16.2857C14.5715 18.1793 13.0365 19.7143 11.1429 19.7143C9.24938 19.7143 7.71436 18.1793 7.71436 16.2857Z'
        fill='currentColor'
      />
    </svg>
  );
}
