import _ from 'lodash';
import React, { Fragment, forwardRef } from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

import { getPotencyStrings } from 'utils/helpers/product';
import useUI from 'hooks/use-ui';
import usePaths from 'hooks/use-paths';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useTranslation from 'hooks/use-translation';
import useProductPrices from 'hooks/use-product-prices';
import useDispensaryFlag from 'shared/hooks/use-dispensary-flag';

import { getProductWeight, isOutOfStock } from 'shared/helpers/products';

import { SponsoredTag } from 'utils/ads/ui';
import { GreyCapsText } from 'components/core';
import { StaffPickChip, ComingSoonChip } from 'components/chips';
import WeightTile from 'components/weight-tile';
import Link from 'components/core/link';
import { heading4 } from 'components/core/typography';
import ProductImage from 'components/product-image';
import SpecialOfferChip from 'components/core/special-offers-chip';
import { useRouter } from 'next/router';
import {
  getSpecialBadgeType,
  formatDiscountForDisplay,
  productIsPartOfActiveSpecial,
  productSatisfiesSaleWeight,
  isNotSolitaryMinimumSpendOffer,
} from 'shared/helpers/specials';
import { useObserver } from 'mobx-react-lite';
import useCart from 'hooks/use-cart';

import { OutOfStock } from './out-of-stock';

const DesktopProductListItem = forwardRef((props, ref) => {
  const {
    product,
    hasStrainType,
    parsedOptions,
    weightedProduct,
    onAddToCart,
    onProductClick,
    showTAC,
    hasBeenVisible,
    addButtonEnabled = false,
    ...restProps
  } = props;
  const category = useDispensaryCategory();
  const { query: routerQuery } = useRouter();
  const UI = useUI();
  const cart = useCart();
  const menuType = useObserver(() => cart.menuType);
  const { href, route } = usePaths({ category, product });
  const { t } = useTranslation();
  const { brand, featured, specialData } = product;
  const isFeatured = featured?.current;
  const isInBogo = productIsPartOfActiveSpecial(product, menuType);
  const { weightedSpecialPrices } = useProductPrices(product);

  const { THC: THCString, CBD: CBDString, TAC: TACString } = getPotencyStrings(product);
  const potencySeparator = '\u00A0\u00A0|\u00A0\u00A0';
  const badgeType = getSpecialBadgeType(product, product?.specialData?.saleSpecials, product?.Options);
  const showWeightSpecials = badgeType !== `none`;
  // we don't want to show the special offer chip on the individual offers page
  const showSpecialOfferChip =
    !routerQuery?.specialId && isInBogo && isNotSolitaryMinimumSpendOffer(specialData.bogoSpecials);

  const comingSoonProductsFlagEnabled = useDispensaryFlag(`rollout.coming-soon-products`, product.DispensaryID);
  const isComingSoon = comingSoonProductsFlagEnabled && product.comingSoon;

  const showOutOfStockUIFlagEnabled = useDispensaryFlag(
    `ecomm.menu.show-out-of-stock-ui.rollout`,
    product.DispensaryID
  );

  // if the product has no quantity available or no options, we want to show the Out of order message
  const showOutOfStock = showOutOfStockUIFlagEnabled && isOutOfStock(product, parsedOptions);

  const getAddToCartHandler = (option) => (event) => {
    // prevent firing encompassing link
    event.preventDefault();
    event.stopPropagation();
    onAddToCart(option);
  };

  const isSponsored = !!product.adTrackers;
  return (
    <Container ref={ref} isVisible={hasBeenVisible} {...restProps}>
      {hasBeenVisible && (
        <>
          <Link href={href} route={route}>
            {/* eslint-disable-next-line styled-components-a11y/anchor-is-valid, styled-components-a11y/click-events-have-key-events, styled-components-a11y/no-static-element-interactions */}
            <ProductInfoContainer onClick={onProductClick}>
              <ProductImageContainer>
                <Image product={product} width={100} height={100} />
              </ProductImageContainer>

              <ProductDetails>
                {(isFeatured || isComingSoon) && (
                  <ProductBadgeContainer>
                    {isFeatured && (
                      <ChipContainer mr='5px'>
                        <StaffPickChip />
                      </ChipContainer>
                    )}
                    {isComingSoon && (
                      <ChipContainer>
                        <ComingSoonChip />
                      </ChipContainer>
                    )}
                  </ProductBadgeContainer>
                )}

                {showSpecialOfferChip && (
                  <ProductBadgeContainer>
                    <ChipContainer>
                      <SpecialOfferChip />
                    </ChipContainer>
                  </ProductBadgeContainer>
                )}

                {isSponsored && <SponsoredTag isSponsored={isSponsored} />}

                <ProductNameContainer>
                  <ProductName>{product.name}</ProductName>
                </ProductNameContainer>

                {!!brand?.name && <ProductBrand>{brand.name}</ProductBrand>}

                <DetailsContainer wrapDetails={hasStrainType && THCString && CBDString}>
                  {hasStrainType && (
                    <StrainPill>
                      <StrainText>{product.strainType}</StrainText>
                    </StrainPill>
                  )}

                  <PotencyInfo>
                    {showTAC && TACString && (
                      <>
                        <GreyCapsText>{t('product-list-item.tac', 'TAC:')}</GreyCapsText>
                        &nbsp;
                        {TACString}
                        {(THCString || CBDString) && potencySeparator}
                      </>
                    )}

                    {THCString && (
                      <>
                        <GreyCapsText>{t('product-list-item.thc', 'THC:')}</GreyCapsText>
                        &nbsp;
                        {THCString}
                      </>
                    )}

                    {THCString && CBDString && potencySeparator}

                    {CBDString && (
                      <>
                        <GreyCapsText>{t('product-list-item.cbd', 'CBD:')}</GreyCapsText>
                        &nbsp;
                        {CBDString}
                      </>
                    )}
                  </PotencyInfo>
                </DetailsContainer>
              </ProductDetails>
            </ProductInfoContainer>
          </Link>

          {showOutOfStock ? (
            <OutOfStock />
          ) : (
            <OptionContainer paddingTop={isFeatured ? `5px` : `3px`} addButtonEnabled={addButtonEnabled}>
              {_.map(parsedOptions, (option) => {
                const weight = getProductWeight(option.value);
                const price = _.find(weightedSpecialPrices, [`weight`, weight]);
                let discountLabel = null;

                if (
                  showWeightSpecials &&
                  product?.specialData?.saleSpecials &&
                  productSatisfiesSaleWeight(product, product.specialData.saleSpecials, option.value)
                ) {
                  discountLabel = formatDiscountForDisplay(
                    price.standardPrice,
                    price.price,
                    product,
                    `sale`,
                    option.value
                  );
                }

                return (
                  <Fragment key={option.label}>
                    <TileContainer>
                      <WeightTile
                        discountLabel={discountLabel}
                        onClick={getAddToCartHandler(option.value)}
                        singleTile={parsedOptions.length <= 1}
                        variant={weightedProduct ? 'weighted' : 'non-weighted'}
                        standardPrice={price.standardPrice}
                        disabled={UI.viewOnlyMode || isComingSoon}
                        addButtonEnabled={addButtonEnabled}
                        {...option}
                      />
                    </TileContainer>
                  </Fragment>
                );
              })}
            </OptionContainer>
          )}
        </>
      )}
    </Container>
  );
});

export default DesktopProductListItem;

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-height: 118px;
  border-bottom: ${({ theme, isVisible }) => (isVisible ? `1px solid ${theme.colors.blueGrey[90]}` : `none`)};
  padding: 20px 8px 20px 0;
  align-items: center;

  &:last-of-type {
    border-bottom: none;
    padding-bottom: 0;
  }

  /* enough width for single line row */
  @media (min-width: 840px) {
    flex-wrap: nowrap;
  }

  @media (min-width: 960px) {
    width: calc(100vw - 270px); /* subtract sidebar width */
  }

  @media (min-width: 1240px) {
    width: 100%;
  }
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 100%;
  order: 0;
  flex-shrink: 1;
  margin-top: 2px;
  max-width: 320px;

  /* enough width for single line row */
  @media (min-width: 840px) {
    order: 1;
    min-width: 316px;
    flex-basis: auto;
    margin-top: 0px;
  }
`;

const Image = styled(ProductImage)`
  order: 1;
  border-radius: 3px;

  /* enough width for single line row */
  @media (min-width: 840px) {
    order: 0;
  }
`;

const OptionContainer = styled.div`
  display: flex;
  flex-basis: 100%;
  justify-content: flex-start;
  margin-left: -5px;
  margin-bottom: 24px;
  overflow-x: auto;
  /* the very tops and bottoms are getting snipped here for some reason */
  padding: ${({ paddingTop }) => paddingTop} 0 6px;
  padding-top: ${({ addButtonEnabled, paddingTop }) => (addButtonEnabled ? `11px` : paddingTop)};
  padding-right: ${({ addButtonEnabled }) => (addButtonEnabled ? `12px` : 0)};
  ::-webkit-scrollbar {
    display: none;
  }

  /* enough width for single line row */
  @media (min-width: 840px) {
    justify-content: flex-start;
    margin-left: 0px;
    flex-basis: auto;
    width: auto;
    margin-bottom: -6px;
    min-width: 0;
  }

  @media (max-width: 1220px) and (min-width: 960px) {
    margin-right: -27px;
  }
`;

const ProductInfoContainer = styled.a`
  min-width: 478px;
  cursor: pointer;
  display: flex;
  flex-grow: 1;
`;

const TileContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 1220px) and (min-width: 960px) {
    :last-of-type {
      padding-right: 25px;
    }
  }
  :first-of-type > button {
    margin-left: 0;
  }
  :last-of-type > button {
    margin-right: 1px;
  }
`;

const ProductBrand = styled.span`
  color: ${({ theme }) => theme.colors.grey[30]};
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 12px;
  line-height: 16px;
`;

const ProductName = styled.span`
  ${heading4}
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primaryBlack};
  line-height: 24px;
  margin-right: 10px;
`;

const DetailsContainer = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  margin-top: 7px;
`;

const StrainPill = styled.div`
  display: flex;
  align-items: center;
  border-radius: 17.5px;
  height: 23px;
  margin-left: -10px;
  margin-right: -10px;

  &:after {
    color: ${({ theme }) => theme.colors.grey[60]};
    content: '\u2022';
    position: relative;
    margin-right: 15px;
    margin-top: -3px;
    margin-left: -5px;

    @media (min-width: 840px) {
      content: '';
      margin: 0;
    }
  }

  /* enough width for single line row */
  @media (min-width: 840px) {
    margin: 0px;
    background-color: ${({ theme }) => theme.colors.blueGrey[95]};
    margin-right: 8px;
  }
`;

const ChipContainer = styled.div`
  ${space}
`;

const ProductBadgeContainer = styled.div`
  display: flex;
  margin-bottom: 7px;
`;

const ProductNameContainer = styled.div``;

const StrainText = styled.p`
  padding: 0 11px;
  color: ${({ theme }) => theme.colors.grey[60]};
  font-weight: bold;
  font-size: 11px;
  white-space: nowrap;

  @media (min-width: 840px) {
    color: ${({ theme }) => theme.colors.grey[30]};
  }
`;

const PotencyInfo = styled.div`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[60]};
  font-style: normal;

  & strong {
    color: currentColor;
  }

  @media (min-width: 840px) {
    color: ${({ theme }) => theme.colors.grey[45]};

    ${GreyCapsText} {
      color: ${({ theme }) => theme.colors.grey[45]};
    }
  }
`;

const ProductImageContainer = styled.div`
  min-width: 100px;
  margin-right: 20px;
  margin-left: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
