import React, { SVGAttributes } from 'react';
import useColors from 'hooks/use-colors';

// rationale: SVG paths are looooong
/* eslint-disable max-len */

type SaleSpecialIcon = SVGAttributes<unknown> & {
  disabled: boolean;
};

export function SaleSpecialGreen(props: SaleSpecialIcon): JSX.Element {
  const { height: heightFromProps, width: widthFromProps, ...otherProps } = props;
  const { green: greenShades } = useColors();

  const height = heightFromProps ?? '40';
  const width = widthFromProps ?? '40';

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' height={height} width={width} {...otherProps}>
      <path
        d='M35.7063 6.11483V4.62173C35.7063 2.5143 33.9918 0.799805 31.8844 0.799805H21.2549C20.234 0.799805 19.2743 1.19738 18.5524 1.91933L3.51955 16.9523C2.7976 17.6741 2.40002 18.6339 2.40002 19.6548C2.40002 20.6757 2.7976 21.6355 3.51955 22.3574L5.04707 23.8849C4.98235 24.1657 4.94807 24.4556 4.94807 24.7508C4.94807 25.7717 5.34565 26.7314 6.0676 27.4533L16.6968 38.0823C17.4419 38.8273 18.4206 39.1998 19.3993 39.1998C20.378 39.1998 21.3567 38.8273 22.1018 38.0822L37.1348 23.0492C37.8567 22.3272 38.2543 21.3675 38.2543 20.3466V9.7176C38.2543 8.05681 37.1891 6.64073 35.7063 6.11483ZM35.6 21.5998L20.4 36.7998C19.9032 37.2966 18.4967 36.8965 18 36.3998L7.60002 25.9998C7.20002 25.5998 7.20002 25.1017 7.20002 24.7508C7.20002 24.3998 7.20002 23.9998 7.60002 23.5998L22.8 8.3998C23.2 7.9998 23.2 7.7748 23.8029 7.7748H35.075C35.7775 7.7748 36.4 8.49735 36.4 9.19981V20.3466C36.4 20.687 35.8406 21.3591 35.6 21.5998Z'
        fill={greenShades[90]}
      />
      <path
        d='M35.7063 6.11483V4.62173C35.7063 2.5143 33.9918 0.799805 31.8844 0.799805H21.2549C20.234 0.799805 19.2743 1.19738 18.5524 1.91933L3.51955 16.9523C2.7976 17.6741 2.40002 18.6339 2.40002 19.6548C2.40002 20.6757 2.7976 21.6355 3.51955 22.3574L5.04707 23.8849C4.98235 24.1657 4.94807 24.4556 4.94807 24.7508C4.94807 25.7717 5.34565 26.7314 6.0676 27.4533L16.6968 38.0823C17.4419 38.8273 18.4206 39.1998 19.3993 39.1998C20.378 39.1998 21.3567 38.8273 22.1018 38.0822L37.1348 23.0492C37.8567 22.3272 38.2543 21.3675 38.2543 20.3466V9.7176C38.2543 8.05681 37.1891 6.64073 35.7063 6.11483ZM4.80002 20.7998C4.55942 20.5592 4.40002 19.9998 4.40002 19.5998C4.40002 19.2595 4.55942 18.6406 4.80002 18.3998L20.4 3.1998C20.6407 2.95928 20.8597 2.7998 21.2 2.7998H32.4C33.1025 2.7998 33.6 3.91928 33.6 4.62173V5.89568H23.8029C22.7819 5.89568 21.8222 6.29325 21.1004 7.0152L6.0676 21.9998L4.80002 20.7998Z'
        fill='white'
      />
      <path
        d='M35.7063 6.11483V4.62173C35.7063 2.5143 33.9918 0.799805 31.8844 0.799805H21.2549C20.234 0.799805 19.2743 1.19738 18.5524 1.91933L3.51955 16.9523C2.7976 17.6741 2.40002 18.6339 2.40002 19.6548C2.40002 20.6757 2.7976 21.6355 3.51955 22.3574L5.04707 23.8849C4.98235 24.1657 4.94807 24.4556 4.94807 24.7508C4.94807 25.7717 5.34565 26.7314 6.0676 27.4533L16.6968 38.0823C17.4419 38.8273 18.4206 39.1998 19.3993 39.1998C20.378 39.1998 21.3567 38.8273 22.1018 38.0822L37.1348 23.0492C37.8567 22.3272 38.2543 21.3675 38.2543 20.3466V9.7176C38.2543 8.05681 37.1891 6.64073 35.7063 6.11483ZM4.80002 20.7998C4.55942 20.5592 4.40002 19.9998 4.40002 19.5998C4.40002 19.2595 4.55942 18.6406 4.80002 18.3998L20.4 3.1998C20.6407 2.95928 20.8597 2.7998 21.2 2.7998H32.4C33.1025 2.7998 33.6 3.91928 33.6 4.62173V5.89568H23.8029C22.7819 5.89568 21.8222 6.29325 21.1004 7.0152L6.0676 21.9998L4.80002 20.7998ZM35.6 21.5998L20.4 36.7998C19.9032 37.2966 18.4967 36.8965 18 36.3998L7.60002 25.9998C7.20002 25.5998 7.20002 25.1017 7.20002 24.7508C7.20002 24.3998 7.20002 23.9998 7.60002 23.5998L22.8 8.3998C23.2 7.9998 23.2 7.7748 23.8029 7.7748H35.075C35.7775 7.7748 36.4 8.49735 36.4 9.19981V20.3466C36.4 20.687 35.8406 21.3591 35.6 21.5998Z'
        fill={greenShades[40]}
        stroke={greenShades[40]}
        strokeWidth={0.5}
      />
      <path
        d='M29.336 10.9917C27.2285 10.9917 25.514 12.7062 25.514 14.8136C25.514 16.921 27.2285 18.6355 29.336 18.6355C31.4434 18.6355 33.1579 16.921 33.1579 14.8136C33.1579 12.7062 31.4434 10.9917 29.336 10.9917Z'
        fill={greenShades[95]}
      />
      <path
        d='M29.336 10.9917C27.2285 10.9917 25.514 12.7062 25.514 14.8136C25.514 16.921 27.2285 18.6355 29.336 18.6355C31.4434 18.6355 33.1579 16.921 33.1579 14.8136C33.1579 12.7062 31.4434 10.9917 29.336 10.9917ZM29.336 16.8876C28.1906 16.8876 27.262 15.9591 27.262 14.8137C27.262 13.6683 28.1906 12.7397 29.336 12.7397C30.4814 12.7397 31.4099 13.6683 31.4099 14.8137C31.4099 15.9591 30.4814 16.8876 29.336 16.8876Z'
        fill={greenShades[40]}
        stroke={greenShades[40]}
        strokeWidth={0.5}
      />
    </svg>
  );
}
