import React from 'react';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import _ from 'lodash';

import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';
import useMenuSortOptions from 'hooks/use-menu-sort-options';

import { Select, Option } from 'components/core';

const useStyles = makeStyles(() => ({
  paper: {
    marginTop: 4,
  },
}));

export default function MenuSort({ onChange }) {
  const { queryParams } = useProductQueryParams();
  const { t } = useTranslation();
  const menuSortOptions = useMenuSortOptions();
  const classes = useStyles();
  const defaultToSortBy = !_.find(menuSortOptions, { key: queryParams.sortby });

  return (
    <StyledSelect
      inputProps={{ 'aria-label': t('common.menuSort', 'Menu Sort') }}
      MenuProps={{ classes: { paper: classes.paper } }}
      onChange={onChange}
      value={defaultToSortBy ? 'sort-by' : queryParams.sortby}
    >
      <StyledOption value='sort-by' key='sort-by' disabled>
        {t('common.sortBy', 'Sort By')}
      </StyledOption>

      {_.map(menuSortOptions, (option) => (
        <StyledOption key={option.key} value={option.value || option.key}>
          {option.label}
        </StyledOption>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  height: 34px;

  fieldset {
    display: none;
  }
`;

const StyledOption = styled(Option)`
  &.MuiMenuItem-root {
    font-family: ${({ theme }) => theme.customized.fonts.secondary};
  }
`;
