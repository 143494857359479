import { useState, useEffect, useMemo } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { Product } from 'types/graphql-overrides';
import { PRODUCT_GROUP_SIZES } from './product-list.constants';

type GroupProductsByRowsArgs = {
  products: Array<Product>;
  isListView: boolean;
  rows: Array<number>;
};

export function useGroupProductsByRows({ products, isListView, rows }: GroupProductsByRowsArgs): Array<Array<Product>> {
  const productsCount = products.length;
  const [productsPerRow, setProductsPerRow] = useState(1);
  const { md, lg, xl } = PRODUCT_GROUP_SIZES.grid;

  const isMedium = useMediaQuery(`(min-width:${md.breakpoint}px)`);
  const isLarge = useMediaQuery(`(min-width:${lg.breakpoint}px)`);
  const isXLarge = useMediaQuery(`(min-width:${xl.breakpoint}px)`);

  useEffect(() => {
    if (isListView) {
      setProductsPerRow(1);
    } else if (isXLarge) {
      setProductsPerRow(xl.rowSize);
    } else if (isLarge) {
      setProductsPerRow(lg.rowSize);
    } else {
      setProductsPerRow(md.rowSize);
    }
  }, [isLarge, isListView, isMedium, isXLarge, lg.rowSize, md.rowSize, productsCount, xl.rowSize]);

  return useMemo(() => {
    const groups: Array<Array<Product>> = [];
    let startIndex = 0;

    [...rows, products.length].forEach((row) => {
      const endIndex = startIndex + productsPerRow * row;

      groups.push(products.slice(startIndex, endIndex));
      startIndex = endIndex;
    });

    return groups;
  }, [rows, products, productsPerRow]);
}
