import React from 'react';
import { useObserver } from 'mobx-react-lite';
import styled from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import NoSsr from '@material-ui/core/NoSsr';

import useUI from 'hooks/use-ui';
import useProductQueryParams from 'src/hooks/use-product-query-params';

import PaginationControls from 'components/core/pagination-controls';
import { WhoopsContent, WhoopsReason } from 'src/components/whoops';
import ProductItemList from './product-item-list';
import ProductCardList from './product-card-list';

export default function ProductList(props) {
  const {
    products,
    dispensary,
    totalPages = 1,
    displaySpecial,
    displayStaffPick,
    trackerSource,
    loading = false,
    productsCarouselQueries,
  } = props;
  const UI = useUI();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(`sm`));

  const menuLayout = useObserver(() => UI.menuLayout);
  const cardView = menuLayout === 'cards';

  const { queryParams, setQueryParams } = useProductQueryParams();

  function handlePageSelect(newPage) {
    setQueryParams({ page: newPage });
    window.scrollTo(0, 0);
  }

  const ListComponent = cardView && !isMobile ? ProductCardList : ProductItemList;

  if (!loading && products?.length === 0) {
    return <WhoopsContent reason={WhoopsReason.noProducts} />;
  }

  return (
    <NoSsr>
      <Container>
        <ListComponent
          products={products}
          dispensary={dispensary}
          displaySpecial={displaySpecial}
          displayStaffPick={displayStaffPick}
          trackerSource={trackerSource}
          productsCarouselQueries={productsCarouselQueries}
        />

        {totalPages > 1 && (
          <PaginationControlsContainer>
            <PaginationControls numPages={totalPages} currentPage={queryParams.page} onPageSelect={handlePageSelect} />
          </PaginationControlsContainer>
        )}
      </Container>
    </NoSsr>
  );
}

const PaginationControlsContainer = styled.div`
  margin-top: 75px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    margin-top: 50px;
  }
`;

const Container = styled.div`
  margin-bottom: 30px;
`;
