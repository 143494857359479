import React from 'react';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import FilterIcon from 'assets/filter-icon';
import { MobileFilterIcon } from 'src/assets/mobile-filter-icon';
import useTranslation from 'hooks/use-translation';
import { Clickable } from 'shared/components';

type DrawerToggleButtonProps = {
  onClick: () => void;
  filterCount?: number;
};

export function DrawerToggleButton({ onClick, filterCount }: DrawerToggleButtonProps): JSX.Element {
  const { t } = useTranslation();
  const flags = useFlags();

  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  return (
    <Clickable onClick={onClick}>
      <ContentContainer>
        {updatedFilterIconsEnabled ? (
          <MobileFilterIcon />
        ) : (
          <OldFilterIconContainer>
            <FilterIcon />
          </OldFilterIconContainer>
        )}
        <Text updatedFilterIconsEnabled={updatedFilterIconsEnabled}>{t('common.filter_plural', 'Filters')}</Text>
        {(filterCount ?? 0) > 0 && (
          <FilterCount updatedFilterIconsEnabled={updatedFilterIconsEnabled}>{filterCount}</FilterCount>
        )}
      </ContentContainer>
    </Clickable>
  );
}

const FilterCount = styled.div`
  border-radius: 50%;
  width: 19px;
  height: 19px;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  padding: 3px;
  display: inline-block;
  margin-left: ${({ updatedFilterIconsEnabled }) => (updatedFilterIconsEnabled ? '0px' : '5px')};
  background: ${({ theme }) => theme.customized.colors.buttonsLinks};
  color: ${({ theme }) => theme.colors.white};
`;

const Text = styled.span`
  font-weight: bold;
  font-size: ${({ updatedFilterIconsEnabled }) => (updatedFilterIconsEnabled ? '14px' : '15px')};
  line-height: 17px;
  color: ${({ theme }) => theme.colors.primaryBlack};
`;

const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  svg {
    color: #828a8f;
  }
`;

const OldFilterIconContainer = styled.div`
  display: flex;
  margin-right: 5px;
`;
