import React from 'react';
import styled, { css } from 'styled-components';
import { space } from 'styled-system';
import noop from 'lodash/noop';

import useColors from 'hooks/use-colors';
import AddIcon from 'assets/add-icon';
import { Clickable } from 'shared/components';
import useDispensary from 'dispensary/hooks/use-dispensary';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';

function determineTileHeight(singleTile, isWeighted, shouldShowStandardPrice) {
  let tileHeight = `47px`;
  const needsAdditionalSpace = singleTile && isWeighted && shouldShowStandardPrice;
  if (needsAdditionalSpace) {
    tileHeight = `67px`;
  } else if (!isWeighted && singleTile && shouldShowStandardPrice) {
    tileHeight = `54px`;
  }
  return tileHeight;
}

export default function WeightTile(props) {
  const {
    label,
    onClick,
    price,
    standardPrice = 0.0,
    singleTile,
    discountLabel = null,
    variant = `weighted`,
    disabled = false,
    addButtonEnabled = false,
  } = props;
  const colors = useColors();
  const { dispensary } = useDispensary();
  const hideDiscount = useHideDiscountFromConsumer(dispensary);

  // eslint-disable-next-line no-use-before-define
  const Tile = singleTile ? SingleTile : MultiTile;
  const isWeighted = variant === 'weighted';
  const shouldShowStandardPrice = +standardPrice !== 0.0 && +price < +standardPrice && !!discountLabel;
  const tileHeight = determineTileHeight(singleTile, isWeighted, shouldShowStandardPrice && !hideDiscount);
  const displayAddToCartIcon = !disabled && !addButtonEnabled;
  const displayAddToCartButton = !disabled && addButtonEnabled;

  return (
    <Tile
      {...(discountLabel ? { className: `on-sale` } : {})}
      onClick={disabled ? noop : onClick}
      disabled={disabled}
      hasDiscount={shouldShowStandardPrice && !hideDiscount}
      tileHeight={tileHeight}
      {...props}
    >
      {displayAddToCartIcon && (
        <ButtonContainer>
          <AddIcon className='add-icon' fill={colors.grey[70]} />
        </ButtonContainer>
      )}
      {isWeighted && (
        <Label>
          {label} {isWeighted && singleTile && '-'}{' '}
        </Label>
      )}
      {shouldShowStandardPrice && !hideDiscount && <StrikedOutPrice>${standardPrice}</StrikedOutPrice>}
      <PriceText>${price}</PriceText>
      {discountLabel && !hideDiscount && <DiscountLabel>{discountLabel}</DiscountLabel>}
      {displayAddToCartButton && (
        <AddButton onClick={onClick}>
          <AddIcon fill={colors.white} />
        </AddButton>
      )}
    </Tile>
  );
}

const AddButton = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
  border-radius: 12px;
  border: none;
  box-shadow: 0 1px 6px ${({ theme }) => theme.colors.basicShadow};
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -6px;
  top: -6px;
  transition: all 0.2s;
  width: 24px;

  svg {
    width: 12px;
    height: 12px;
  }

  &:hover {
    background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
  }
`;

const DiscountLabel = styled.div`
  background: ${({ theme }) => theme.colors.red[60]} !important;
  border-radius: 0px 0px 6px 6px;
  bottom: -6px;
  color: #fff;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  padding: 2px 0;
  position: absolute;
  text-align: center;
  width: 100%;
`;

const tileStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  border-radius: ${({ theme }) => theme.customized.radius.sm};
  position: relative;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.grey[60] : theme.colors.primaryBlack)};

  ${({ theme }) => theme.breakpoints.up(`sm`)} {
    &:hover,
    &:active,
    &:focus {
      background-color: ${({ theme, disabled }) => (disabled ? theme.colors.blueGrey[95] : theme.colors.blueGrey[90])};

      div {
        border-color: ${({ theme }) => theme.customized.colors.buttonsLinks};
        background-color: ${({ theme }) => theme.colors.white};
      }

      .add-icon {
        fill: ${({ theme }) => theme.customized.colors.buttonsLinks};
      }
    }
  }
`;

const SingleTile = styled(Clickable)`
  ${tileStyles};
  flex-direction: ${({ hasDiscount }) => (hasDiscount ? `column` : `row`)};
  height: ${({ tileHeight }) => tileHeight};
  width: 175px;
  justify-content: center;
  margin-top: 0px;
  user-select: none;
  flex: 0 0 auto;

  span {
    padding: 0 2px;
  }

  ${space}
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    height: 33px;
    width: 153px;
  }

  &.on-sale {
    padding-bottom: 8px;

    ${({ theme }) => theme.breakpoints.down(`sm`)} {
      height: ${({ tileHeight }) => tileHeight};
    }
  }
`;

const MultiTile = styled(Clickable)`
  ${tileStyles};
  flex-direction: column;
  height: ${({ hasDiscount }) => (hasDiscount ? `68px` : `62px`)};
  width: 77px;
  position: relative;
  margin: 0 6px;

  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    height: ${({ hasDiscount }) => (hasDiscount ? `65px` : `56px`)};
    width: 71px;
  }
`;

const ButtonContainer = styled.span`
  display: block;
  border: 1px solid ${({ theme }) => theme.colors.grey[70]};
  background-color: ${({ theme }) => theme.colors.blueGrey[95]};
  border-radius: 50%;
  height: 17px;
  width: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -1px;
  right: -1px;
`;

const Label = styled.span`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  font-size: 11px;
  line-height: 11px;
  max-width: 100px;
  overflow: hidden;
  padding-bottom: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const PriceText = styled.span`
  font-size: 13px;
  font-weight: bold;
  line-height: 16px;
`;

const StrikedOutPrice = styled(PriceText)`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey[70]};
  text-decoration-line: line-through;
`;
