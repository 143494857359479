import React from 'react';

import { categoryPluralize } from 'shared/helpers/products';
import BlueSparkle from 'src/assets/blue-sparkle-icon';
import { Category } from 'src/generics/data/generic-category/data.types';
import { Product } from 'src/utils/helpers/product';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';

import { CategoryRecommendationsCarousel } from './category-recommendations-carousel';

type LoggedInProps = {
  products: Product[];
  loading: boolean;
};

export function LoggedIn({ products, loading }: LoggedInProps): JSX.Element | null {
  const dispensaryCategory = useDispensaryCategory();
  const category = dispensaryCategory?.value as Category;
  const heading = `${categoryPluralize(category) as string} for you`;
  const subheading = 'Based on your shopping history';

  return (
    <CategoryRecommendationsCarousel
      products={products}
      loading={loading}
      heading={heading}
      subheading={subheading}
      icon={<BlueSparkle />}
    />
  );
}
