import React from 'react';

import useTranslation from 'src/hooks/use-translation';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import Section from 'src/components/sidebar-filters/section';
import { CheckboxGroup } from 'src/components/core';
import { getEffectsAsOptions } from 'src/utils/helpers/effects-filtering';

type EffectsFilterProps = {
  effects: string[];
};

export function EffectsFilter({ effects }: EffectsFilterProps): JSX.Element {
  const { t } = useTranslation();
  const { defaults, queryParams, setQueryParams } = useProductQueryParams();
  const currentEffects = queryParams.effects;

  const effectOptions = getEffectsAsOptions(effects);

  const handleOptionClick = (effectValue: string): void => {
    const effectsSet = new Set(currentEffects);

    if (effectsSet.has(effectValue)) {
      effectsSet.delete(effectValue);
    } else {
      effectsSet.add(effectValue);
    }

    setQueryParams({
      effects: Array.from(effectsSet),
      page: defaults.page,
    });
  };

  return (
    <Section startsOpen heading={t('common.effect_plural', 'Effects')}>
      <CheckboxGroup options={effectOptions} selectedOptions={currentEffects} onSelect={handleOptionClick} />
    </Section>
  );
}
