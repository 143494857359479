import { CategoryProductsCarouselsQueries } from 'src/dispensary/hooks/use-category-products-for-carousels-queries';

type ShowInterstitialCarousels = {
  showSponsoredBrandsCarousel: boolean;
  showCategoryRecommendationsCarousel: boolean;
};

export function getShowInterstitialCarousels(
  productsCarouselQueries?: CategoryProductsCarouselsQueries
): ShowInterstitialCarousels {
  const { sponsoredBrandQuery, personalizedProductsQuery } = productsCarouselQueries ?? {
    sponsoredBrandQuery: { data: { getSponsoredBrand: { products: null } }, loading: false, error: null },
    personalizedProductsQuery: { data: { getPersonalizedProducts: { products: null } }, loading: false, error: null },
  };

  const showSponsoredBrandsCarousel =
    !sponsoredBrandQuery.loading &&
    !sponsoredBrandQuery.error &&
    !!sponsoredBrandQuery.data?.getSponsoredBrand?.products?.length;

  const showCategoryRecommendationsCarousel =
    !personalizedProductsQuery.loading &&
    !personalizedProductsQuery.error &&
    !!personalizedProductsQuery.data?.getPersonalizedProducts?.products?.length;

  return {
    showSponsoredBrandsCarousel,
    showCategoryRecommendationsCarousel,
  };
}
