import useProductQueryParams from 'hooks/use-product-query-params';
import { GenericCategory } from 'src/generics/data';

type UseCategorySubcategoryFilterReturn = {
  selectedCategory: GenericCategory;
  selectedSubcategories: string[] | [];
  handleClickCategory: (categoryValue: string | null) => void;
  handleClickSubcategory: (subcategoryValue: string | null) => void;
};

export function useCategorySubcategoryFilter(): UseCategorySubcategoryFilterReturn {
  const { defaults, queryParams, setQueryParams } = useProductQueryParams();
  const selectedCategory = queryParams.category;
  const selectedSubcategories = queryParams.subcategories;

  const handleClickSubcategory = (subcategoryValue: string | null): void => {
    setQueryParams({ subcategories: [subcategoryValue], page: defaults.page });
  };

  const handleClickCategory = (categoryValue: string | null): void => {
    setQueryParams({
      category: categoryValue ?? defaults.category,
      subcategories: defaults.subcategories,
      page: defaults.page,
    });
  };

  return {
    selectedCategory,
    selectedSubcategories,
    handleClickCategory,
    handleClickSubcategory,
  };
}
