import React from 'react';
import { Clickable } from 'shared/components';
import styled from 'styled-components';

type ListItemProps = {
  selected: boolean;
  secondary?: boolean;
  onClick: () => void;
  label: string;
};

export function ListItem(props: ListItemProps): JSX.Element {
  const { selected, secondary, onClick, label } = props;
  return (
    <ListItemContainer>
      <Anchor selected={selected} secondary={secondary} onClick={onClick}>
        {label}
      </Anchor>
    </ListItemContainer>
  );
}

const ListItemContainer = styled.li`
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

const Anchor = styled(Clickable)`
  font-size: 13px;
  color: ${({ theme, secondary }) => (secondary ? `#4F5D68` : theme.colors.primaryBlack)};
  font-weight: ${(props) => (props.selected ? `bold` : `normal`)};
  line-height: 2.31;
  display: flex;
  width: 100%;
`;
