import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import { useObserver } from 'mobx-react-lite';
import { makeStyles } from '@material-ui/core/styles';

import { MenuLayoutOptions } from 'shared/constants/dispensaries';
import { Select, Option } from 'components/core';
import ListIcon from 'assets/list-icon';
import CardsIcon from 'assets/cards-icon';

import useUI from 'hooks/use-ui';
import useTranslation from 'hooks/use-translation';

const IconMap = {
  list: <ListIcon />,
  cards: <CardsIcon />,
};

const useStyles = makeStyles(() => ({
  paper: {
    width: 140,
    marginTop: 4,
  },
}));

export default function MenuLayoutSelector() {
  const UI = useUI();
  const { t } = useTranslation();
  const menuLayout = useObserver(() => UI.menuLayout);

  const classes = useStyles();

  return (
    <StyledSelect
      MenuProps={{ classes: { paper: classes.paper } }}
      inputProps={{ 'aria-label': t('common.menuLayout', 'Menu Layout') }}
      onChange={(e) => UI.setMenuLayout(e.target.value)}
      value={menuLayout}
    >
      {map(MenuLayoutOptions, (option) => (
        <Option key={option.key} value={option.key}>
          <Label>
            {option.label}: {IconMap[option.key]}
          </Label>
        </Option>
      ))}
    </StyledSelect>
  );
}

const StyledSelect = styled(Select)`
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  height: 34px;
  margin-left: 11px;

  .MuiSelect-select {
    display: flex;
    justify-content: center;
  }

  fieldset {
    display: none;
  }
`;

const Label = styled.div`
  align-items: center;
  display: flex;
  font-family: ${({ theme }) => theme.customized.fonts.secondary};
  justify-content: space-between;
  width: 100%;

  svg {
    margin-left: 9px;
  }
`;
