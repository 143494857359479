import React, { forwardRef, memo } from 'react';
import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';
import { SaleSpecialGreen as SalesSpecialIcon } from 'src/svg/sale-special-green';
import useTranslation from 'hooks/use-translation';
import { useHideDiscountFromConsumer } from 'shared/hooks/use-hide-discounts-from-consumer';
import useDispensary from 'dispensary/hooks/use-dispensary';

const SlideCounter = ({ currentSlide, totalSlides }) => (
  <SlideCounterContainer>
    ({currentSlide}/{totalSlides})
  </SlideCounterContainer>
);

const ChipText = ({ children, currentSlide, totalSlides }) => (
  <>
    <ChipTextContainer>{children}</ChipTextContainer>
    {totalSlides > 1 && <SlideCounter currentSlide={currentSlide} totalSlides={totalSlides} />}
  </>
);

const ChipTextContainer = styled.span`
  font-weight: bold;
  margin: 0px 5px;
`;

const SlideCounterContainer = styled.span`
  font-weight: normal;
`;

const SpecialOffersChip = forwardRef((props, ref) => {
  const { currentSlide = 1, totalSlides = 1, size = `medium`, ...otherProps } = props;
  const { t } = useTranslation();
  const fontSize = size === 'large' ? `16px` : `13px`;
  const { dispensary } = useDispensary();
  const hideDiscount = useHideDiscountFromConsumer(dispensary);

  return (
    !hideDiscount && (
      <ChipContainer fontSize={fontSize}>
        <StyledChip
          variant='default'
          avatar={<SalesSpecialIcon />}
          label={
            <ChipText currentSlide={currentSlide} totalSlides={totalSlides}>
              {t('special-offers-chip.special-offer', 'Special offer')}
            </ChipText>
          }
          fontSize={fontSize}
          {...otherProps}
          ref={ref}
        />
      </ChipContainer>
    )
  );
});

export default memo(SpecialOffersChip);

const ChipContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: ${(props) => props.fontSize};
  color: ${({ theme }) => theme.colors.primaryGreen};
`;

const StyledChip = styled(Chip)`
  &.MuiChip-root {
    font-size: ${(props) => props.fontSize};
    line-height: 21.45px;
    font-weight: 700;
    padding: 0;
    height: auto;
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primaryGreen};

    & > .MuiChip-label {
      padding-left: 9px;
    }

    & > .MuiChip-avatar {
      margin-left: 0px;
    }
  }
`;

StyledChip.defaultProps = {
  size: `small`,
};
