import React from 'react';
import { useObserver } from 'mobx-react-lite';
import styled, { css } from 'styled-components';
import { bindHover, bindPopover, bindTrigger, PopupState as PopupStateType } from 'material-ui-popup-state/hooks';
import PopupState from 'material-ui-popup-state';
import useUI from 'src/hooks/use-ui';
import { Typography } from 'src/typography';

import SvgChevronIcon from 'src/assets/chevron-icon';
import SvgListIcon from 'src/assets/list-icon';
import SvgCardsIcon from 'src/assets/cards-icon';

import { buttonResetStyles, PopoverNavButton, StyledHoverPopover } from './menu-layout-selector-v2.styles';

const IconMap = {
  list: <SvgListIcon color='#2C3236' />,
  cards: <SvgCardsIcon color='#2C3236' />,
};

export function MenuLayoutSelectorV2(): JSX.Element {
  const UI = useUI();
  const menuLayout = useObserver(() => UI.menuLayout);

  const handleClick = (key: string, popupState: PopupStateType): void => {
    UI.setMenuLayout(key);
    void popupState.close();
  };

  return (
    <PopupState variant='popover' popupId='menuSortPopover'>
      {(popupState) => (
        <>
          <LayoutTypeButton
            {...bindHover(popupState)}
            {...bindTrigger(popupState)}
            data-testid='menu-layout-selector-v2-popover-toggle'
          >
            <LayoutLabel size='small'>{menuLayout}</LayoutLabel>
            {IconMap[menuLayout]}
            <SvgChevronIcon height='7px' width='12px' />
          </LayoutTypeButton>

          <StyledHoverPopover
            {...bindPopover(popupState)}
            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            data-testid='menu-layout-selector-v2-popover'
            $offsetTop
          >
            <nav>
              {Object.entries(IconMap).map(([key, value]) => (
                <PopoverNavButton
                  key={key}
                  onClick={() => handleClick(key, popupState)}
                  $isActive={menuLayout === key}
                  data-testid={`menu-sort-v2-select-${key}`}
                >
                  <NavButtonContent>
                    {key}
                    {value}
                  </NavButtonContent>
                </PopoverNavButton>
              ))}
            </nav>
          </StyledHoverPopover>
        </>
      )}
    </PopupState>
  );
}

const layoutSelectionStyles = css`
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
  svg {
    color: #828a8f;
  }
`;

const LayoutTypeButton = styled.button`
  ${layoutSelectionStyles}
  ${buttonResetStyles}
  align-items: center;
  display: flex;
  gap: 8px;
  position: relative;

  &::after {
    content: '';
    height: 8px;
    left: 0;
    position: absolute;
    top: 100%;
    width: 100%;
  }
`;

const LayoutLabel = styled(Typography.Label)`
  text-transform: capitalize;
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

const NavButtonContent = styled.div`
  text-transform: capitalize;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
