import React from 'react';

function SvgListIcon(props) {
  const { color = '#788289' } = props;
  return (
    <svg height={10} width={13} {...props}>
      <path
        d='M1 0h11a1 1 0 010 2H1a1 1 0 110-2zm0 4h11a1 1 0 010 2H1a1 1 0 110-2zm0 4h11a1 1 0 010 2H1a1 1 0 010-2z'
        fill={color}
      />
    </svg>
  );
}

export default SvgListIcon;
