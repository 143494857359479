import _ from 'lodash';
import { CategoriesWithPotencies } from 'shared/constants';
import { potencyIsDefault } from './product';

export function getProductsFilterCount({ queryParams, category = {}, onBrandsPage = false }) {
  const { weight, potencycbd = [], potencythc = [], brands, straintypes, effects } = queryParams;
  const shouldCountPotency = _.includes(CategoriesWithPotencies, category?.key);
  const thcIsDefault = potencyIsDefault(potencythc, category);
  const cbdIsDefault = potencyIsDefault(potencycbd, category);

  let count = 0;
  count += straintypes?.length || 0;

  if (!onBrandsPage) {
    count += brands?.length || 0;
  }
  if (shouldCountPotency && !thcIsDefault) {
    count += 1;
  }
  if (shouldCountPotency && !cbdIsDefault) {
    count += 1;
  }
  if (weight) {
    count += 1;
  }
  if (effects?.length) {
    count += effects.length;
  }
  return count;
}
