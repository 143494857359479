import React from 'react';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { useRouter } from 'next/router';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { isWeightedProduct } from 'shared/helpers/products';

import { useSponsoredProductHandlers } from 'utils/ads/hooks/sponsored-products';
import { groupOptionsWithPrices } from 'utils/helpers/product';
import useCart from 'src/hooks/use-cart';
import useUI from 'src/hooks/use-ui';

import useViewportVisibility from 'hooks/use-viewport-visibility';

import DesktopProductListItem from './desktop-product-list-item';
import MobileProductListItem from './mobile-product-list-item';

const ProductListItem = ({ product, productIndex, isMobile, onClick, onAddToCart }) => {
  const { ref, hasBeenVisible } = useViewportVisibility();
  const { handleSponsoredProductClick } = useSponsoredProductHandlers({ product, hasBeenVisible });

  const { menuType } = useCart();
  const UI = useUI();
  const { isKiosk } = UI;

  const flags = useFlags();
  const isAddToCartCTAEnabled = flags['growth.ecomm.add-to-cart-cta.experiment'] ?? false;

  const showTAC = !_.isEmpty(product?.cannabinoidsV2);

  const router = useRouter();
  const viewingOffersPage = _.includes(router.route, 'specials/offer');
  const { specialId } = router.query;

  const hasStrainType = product.strainType && product.strainType !== 'N/A';
  const parsedOptions = groupOptionsWithPrices({
    product,
    menuType,
    isKiosk,
    offerSpecial: viewingOffersPage,
    specialId,
  });

  const weightedProduct = isWeightedProduct(product);

  const handleAddToCart = (option) => {
    onAddToCart(product, productIndex, option);
    handleSponsoredProductClick();
  };

  const handleClick = () => {
    onClick(product, productIndex);
    handleSponsoredProductClick();
  };

  const ListItem = isMobile ? MobileProductListItem : DesktopProductListItem;

  return (
    <ListItem
      hasBeenVisible={hasBeenVisible}
      ref={ref}
      product={product}
      hasStrainType={hasStrainType}
      parsedOptions={parsedOptions}
      weightedProduct={weightedProduct}
      onAddToCart={handleAddToCart}
      onProductClick={handleClick}
      showTAC={showTAC}
      data-testid='product-list-item'
      addButtonEnabled={isAddToCartCTAEnabled}
    />
  );
};

export default observer(ProductListItem);
