import _ from 'lodash';
import { useRouter } from 'next/router';

const filterQueries = [`straintypes`, `weight`, `brands`, `potencythc`, `potencycbd`, `category`];

export function useProductFiltersApplied() {
  const router = useRouter();
  const queries = _.keys(router?.query);
  return _.some(queries, (query) => _.includes(filterQueries, query));
}
