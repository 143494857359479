import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { SponsoredBrandCarouselV2 } from 'src/components/carousels/ads-carousels/sponsored-brand-carousel-v2';
import { CategoryPageRecommendations } from 'src/components/carousels/personalization/category-page-recommendations';
import { ListViewProductCard, ProductCardsGrid } from 'src/components/product-card';
import { PRODUCT_GRID_TEST_ID } from 'src/components/product-list/product-list.constants';

import useUI from 'src/hooks/use-ui';
import { useAddToCartModal, findSingleWeightItem } from 'components/modals/add-to-cart';
import { tracker } from 'src/utils/analytics';

import { useGroupProductsByRows } from './use-group-products-by-rows';
import { getShowInterstitialCarousels } from './product-list.utils';

const ProductCardList = ({ products, dispensary, trackerSource, productsCarouselQueries }) => {
  const { showSponsoredBrandsCarousel, showCategoryRecommendationsCarousel } = getShowInterstitialCarousels(
    productsCarouselQueries
  );
  const { sponsoredBrandQuery, personalizedProductsQuery } = productsCarouselQueries ?? {
    sponsoredBrandQuery: null,
    personalizedProductsQuery: null,
  };

  const UI = useUI();
  const { open: openAddToCartModal } = useAddToCartModal();

  const groupedProducts = useGroupProductsByRows({
    products,
    isListView: false,
    rows: showSponsoredBrandsCarousel ? [2, 2] : [0, 2],
  });

  const handleClick = useCallback(
    (product, index) => {
      tracker.setContext({
        activeProductPosition: index,
      });
      tracker.productClicked({ product, position: index, trackerSource });

      UI.activeProductSource = trackerSource;
      UI.setProductPosition({
        productId: product.id,
        productIndex: index,
      });
    },
    [UI, trackerSource]
  );

  const handleQuickAddClick = useCallback(
    (product, index) => {
      openAddToCartModal({
        ...findSingleWeightItem(product),
        product,
        dispensary,
        trackerSource,
      });
      handleClick(product, index);
    },
    [dispensary, handleClick, openAddToCartModal, trackerSource]
  );

  const sharedProps = {
    onClick: handleClick,
    onQuickAddClick: handleQuickAddClick,
  };

  return (
    <ProductCardsGrid dataTestId={PRODUCT_GRID_TEST_ID}>
      {groupedProducts[0].map((product, index) => (
        <ListViewProductCard key={product.id} product={product} productIndex={index} {...sharedProps} />
      ))}

      {showSponsoredBrandsCarousel && (
        <AdUnit data-testid='ad-unit-sponsored-brands-carousel'>
          <SponsoredBrandCarouselV2 sponsoredBrandQuery={sponsoredBrandQuery} />
        </AdUnit>
      )}

      {groupedProducts[1].map((product, index) => (
        <ListViewProductCard key={product.id} product={product} productIndex={index} {...sharedProps} />
      ))}

      {showCategoryRecommendationsCarousel && (
        <AdUnit data-testid='ad-unit-category-recommendations-carousel'>
          <CategoryPageRecommendations personalizedProductsQuery={personalizedProductsQuery} />
        </AdUnit>
      )}

      {groupedProducts[2].map((product, index) => (
        <ListViewProductCard key={product.id} product={product} productIndex={index} {...sharedProps} />
      ))}
    </ProductCardsGrid>
  );
};

export default observer(ProductCardList);

const AdUnit = styled.aside`
  grid-column: 1 / -1;

  @media (min-width: 960px) and (max-width: 1240px) {
    width: calc(100vw - 270px);
  }

  & > div {
    margin: 10px auto;
    padding: 0;
  }
`;
