import React, { useState, useEffect, useMemo } from 'react';
import _ from 'lodash';
import styled from 'styled-components';

import { CategoryPotencyRanges, CategoryPotencyUnits } from 'shared/constants';
import { rangeIsValid } from 'utils/helpers/product';

import RangeSlider from 'components/core/range-slider';

export function PotencySlider({ onChange, value: valueFromProps, category, title }) {
  const maxRangeForCategory = useMemo(() => CategoryPotencyRanges[category?.key] || [0, 50], [category]);
  const unit = _.get(CategoryPotencyUnits, category?.key, `%`);

  // This is the local value shown in the slider.
  // The valueFromProps is only updated on mouseUp so we need to keep track of this in a separate variable.
  const [localValue, setLocalValue] = useState(valueFromProps ? [...valueFromProps] : [...maxRangeForCategory]);

  // It's possible for the localValue to fall outside of the maxRange when switching categories.
  // Reset the localValue whenever the valueFromProps or maxRange changes and make sure it's valid for the category.
  useEffect(() => {
    const newValue = rangeIsValid(valueFromProps, maxRangeForCategory) ? valueFromProps : maxRangeForCategory;
    setLocalValue([...newValue]);
  }, [maxRangeForCategory, valueFromProps]);

  // Updates the value displayed in the slider
  function handleLocalChange(_event, value) {
    setLocalValue(value);
  }

  function getAriaValueText(value, index) {
    return `${value}${unit} ${index === 0 ? `Minimum` : `Maximum`} ${title}`;
  }

  function getAriaLabel(index) {
    return index === 0 ? `Minimum ${title} Filter` : `Maximum ${title} Filter`;
  }

  return (
    <>
      <SliderLabel marginTop={14}>
        <SliderLabelType>{title}:</SliderLabelType>
        {localValue[0]}
        {unit} - {localValue[1]}
        {unit}
      </SliderLabel>
      <StyledRangeSlider
        getAriaLabel={getAriaLabel}
        getAriaValueText={getAriaValueText}
        max={[...maxRangeForCategory][1]}
        min={[...maxRangeForCategory][0]}
        onChange={handleLocalChange}
        onChangeCommitted={onChange}
        value={[...localValue]}
        width={90}
      />
    </>
  );
}

const StyledRangeSlider = styled((props) => <RangeSlider {...props} />)`
  ${({ theme }) => theme.breakpoints.down(`sm`)} {
    &&& {
      width: 80%;
      .MuiSlider-rail {
        height: 12px;
      }
      .MuiSlider-track {
        height: 12px;
      }
      .MuiSlider-thumb {
        height: 25px;
        width: 25px;
        margin-top: -7px;
      }
    }
  }
`;

export const SliderLabel = styled.div`
  font-size: 12px;
  line-height: 30px;
  margin-top: ${(props) => (props.marginTop ? props.marginTop : 6)}px;
`;

export const SliderLabelType = styled.span`
  font-weight: bold;
  margin-right: 4px;
`;
