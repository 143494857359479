import _ from 'lodash';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { AllSubcategories, MenuHeaderDisplayCategories } from 'shared/constants';
import useTranslation from 'hooks/use-translation';
import useUI from 'hooks/use-ui';
import useProductQueryParams from 'src/hooks/use-product-query-params';
import { useRouter } from 'next/router';
import useMenuFilterOptions from 'src/hooks/use-menu-filter-options';
import { getBrand } from 'src/utils/get-brand';
import { useIsMobile } from 'src/hooks/use-is-mobile';

export function useMenuHeaderHeading(params) {
  const { category: pageCategory, page, collection } = params;
  const { t } = useTranslation();
  const { onSearchPage } = useUI();
  const { queryParams } = useProductQueryParams();
  const { route, query } = useRouter();
  const { brands } = useMenuFilterOptions({ skipCurrentFilters: true });
  const { brand: currentBrand } = getBrand({ brands, brandId: query.brandId });
  const isMobile = useIsMobile();
  const flags = useFlags();

  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  if (!onSearchPage) {
    if (page === 'brands') {
      return currentBrand ? currentBrand.name : t('common.brand-plural', 'Brands');
    }

    if (page === 'bogo special') {
      return t('specials.individual-offer-header', 'Eligible Items');
    }

    if (_.includes(route, 'specials')) {
      return t(`specials.items-on-special-header`, `Items on Special`);
    }

    if (_.includes(route, 'collection')) {
      return t(`collection.all-collection-header`, {
        defaultValue: `{{collection}}`,
        collection,
      });
    }

    if (_.isEmpty(query.subcategory)) {
      return t(`specials.all-categories-header`, {
        defaultValue: updatedFilterIconsEnabled && isMobile ? `{{category}}` : `All {{category}}`,
        category: pageCategory?.label,
      });
    }

    if (query.subcategory) {
      return _.find(AllSubcategories, { value: query.subcategory })?.label;
    }
  }

  if (queryParams.subcategories[0]) {
    return _.find(AllSubcategories, { value: queryParams.subcategories[0] })?.label;
  }

  if (!queryParams.subcategories[0] && queryParams.category) {
    return `All ${MenuHeaderDisplayCategories?.[queryParams.category]}`;
  }

  return t(`specials.all-categories-header`, {
    defaultValue: `{{category}}`,
    category: MenuHeaderDisplayCategories.all,
  });
}
