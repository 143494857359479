import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useAmplitude } from 'shared/hooks/use-amplitude';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { getProductsFilterCount } from 'utils/helpers/ui';
import { potencyIsDefault } from 'utils/helpers/product';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import useDispensary from 'src/dispensary/hooks/use-dispensary';
import useProductQueryParams from 'hooks/use-product-query-params';
import useMenuSortOptions from 'hooks/use-menu-sort-options';
import useMenuFilterOptions from 'hooks/use-menu-filter-options';
import useUI from 'hooks/use-ui';
import { getEffectsAsOptions } from 'src/utils/helpers/effects-filtering';

import { MenuFiltersDrawer } from './menu-filters-drawer';

export function MenuFiltersDrawerController({ open, onClose }) {
  const UI = useUI();
  const category = useDispensaryCategory();
  const { dispensary } = useDispensary();
  const { query, route } = useRouter();
  const { queryParams, setQueryParams, resetQueryParams, defaults } = useProductQueryParams();
  const amplitude = useAmplitude();
  const flags = useFlags();

  const onBrandsPage = _.includes(route, 'brands');
  const onOffersPage = _.includes(route, 'offer');
  const onSalesPage = _.includes(route, 'sale');
  let specialType;
  if (onOffersPage) {
    specialType = `bogo`;
  } else if (onSalesPage) {
    specialType = `sale`;
  }

  const { brands, strainTypes, weights, effects } = useMenuFilterOptions({ specialId: query?.specialId, specialType });
  const sortOptions = useMenuSortOptions();

  const [selectedFilters, setSelectedFilters] = useState(queryParams);
  const [filterCount, setFilterCount] = useState(getProductsFilterCount({ queryParams, category, onBrandsPage }));

  const selectedCategory = queryParams.category;
  const selectedSubcategories = queryParams.subcategories;
  const { search } = queryParams;
  const updatedFilterIconsEnabled = flags['growth.ecomm.filter-icon-update.rollout'] ?? false;

  useEffect(() => {
    if (selectedFilters.sortby !== queryParams.sortby && updatedFilterIconsEnabled) {
      setSelectedFilters({ ...selectedFilters, sortby: queryParams.sortby });
    }
  }, [queryParams.sortby, selectedFilters, updatedFilterIconsEnabled]);

  function applyFilters() {
    setQueryParams({
      ...selectedFilters,
      category: selectedCategory,
      subcategories: selectedSubcategories,
      search,
      page: defaults.page,
    });

    UI.showMobileFilters = false;

    if (selectedFilters?.brands.length > 0) {
      amplitude.log(`Brand Filter`, {
        description: `User clicks/taps on Brand Filter while shopping`,
        brandSelected: selectedFilters.brands.join(', '),
      });
    }
    if (!potencyIsDefault(selectedFilters?.potencycbd, category)) {
      amplitude.log(`Potency CBD Filter`, {
        description: `User clicks/taps on Potency CBD Filter while shopping`,
        potencyCBDSelected: selectedFilters?.potencycbd.join(', '),
      });
    }
    if (!potencyIsDefault(selectedFilters?.potencythc, category)) {
      amplitude.log(`Potency THC Filter`, {
        description: `User clicks/taps on Potency THC Filter while shopping`,
        potencyTHCSelected: selectedFilters?.potencythc.join(', '),
      });
    }
    if (selectedFilters?.sortby !== 'default') {
      amplitude.log(`Sort By Filter`, {
        description: `User clicks/taps on Sort By Filter while shopping`,
        sortBySelected: selectedFilters.sortby,
      });
    }
    if (selectedFilters?.straintypes?.length > 0) {
      amplitude.log(`Strain Types Filter`, {
        description: `User clicks/taps on Strain Types Filter while shopping`,
        strainTypesSelected: selectedFilters.straintypes?.join(', '),
      });
    }
    if (selectedFilters?.weight) {
      amplitude.log(`Weight Filter`, {
        description: `User clicks/taps on Weight Filter while shopping`,
        weightSelected: selectedFilters.weight,
      });
    }
  }

  function clearFilters() {
    setFilterCount(0);
    setSelectedFilters(defaults);

    if (onBrandsPage) {
      setQueryParams({ ...defaults, brands: queryParams.brands });
    } else {
      resetQueryParams();
    }
  }

  function updateFilter(filter, value) {
    if (filter === `potencythc` || filter === `potencycbd`) {
      if (potencyIsDefault(value, category) && !potencyIsDefault(selectedFilters[filter], category)) {
        setFilterCount(filterCount - 1);
      } else if (potencyIsDefault(selectedFilters[filter], category) && !potencyIsDefault(value, category)) {
        setFilterCount(filterCount + 1);
      }
      setSelectedFilters({ ...selectedFilters, [filter]: value });
    } else if (_.isArray(selectedFilters[filter])) {
      const newArray = _.xor(selectedFilters[filter], [value]);
      setFilterCount(filterCount + (newArray.length - selectedFilters[filter].length));
      setSelectedFilters({ ...selectedFilters, [filter]: newArray });

      // fixes bug where these get out of sync
      if (filter === `brands` && queryParams.brands !== selectedFilters.brands) {
        setQueryParams({ ...selectedFilters, brands: newArray });
      }
    } else {
      const removeFilter = value === selectedFilters[filter];
      if (!selectedFilters[filter]) {
        setFilterCount(filterCount + 1);
      }
      if (removeFilter) {
        setFilterCount(filterCount - 1);
      }
      setSelectedFilters({ ...selectedFilters, [filter]: removeFilter ? undefined : value });
    }
  }

  const consolidatedBrands = _.uniqBy(brands, `name`);
  const parsedBrands = _.map(consolidatedBrands, ({ id, name }) => ({
    key: id,
    value: _.kebabCase(name),
    label: name,
  }));

  const effectOptions = getEffectsAsOptions(effects);

  return (
    <MenuFiltersDrawer
      open={open}
      strainTypeOptions={strainTypes}
      weightOptions={weights}
      brandOptions={parsedBrands}
      sortOptions={sortOptions}
      effectOptions={effectOptions}
      selectedFilters={selectedFilters}
      filterCount={filterCount}
      onClose={onClose}
      updateFilter={updateFilter}
      clearFilters={clearFilters}
      applyFilters={applyFilters}
      hidePotencyFilters={dispensary?.storeSettings?.hideFilters}
      onBrandsPage={onBrandsPage}
      category={category}
      isEmbedded={UI.isEmbedded}
      showMenuSortFilter={!updatedFilterIconsEnabled}
    />
  );
}
