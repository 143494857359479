import React from 'react';
import { Link } from 'components/core';
import styled, { css } from 'styled-components';
import _ from 'lodash';
import { useRouter } from 'next/router';
import { useAmplitude } from 'shared/hooks/use-amplitude';

import useProductQueryParams from 'hooks/use-product-query-params';
import { useDispensaryCategory } from 'src/hooks/use-dispensary-category';
import usePaths from 'hooks/use-paths';
import useUI from 'src/hooks/use-ui';
import ROUTES from 'src/routes';

const VARIANT_STYLES_MAP = {
  default: css`
    ${({ theme, selected }) => `
      background: ${selected ? theme.colors.grey[35] : theme.colors.white};
      border: ${selected ? `none` : `solid 1px ${theme.colors.blueGrey[80]}`};
      color: ${selected ? theme.colors.white : theme.colors.grey[25]};
    `}
  `,
  light: css`
    ${({ theme, selected }) => `
      background: ${theme.colors.white};
      border: ${selected ? `solid 2px ${theme.customized.colors.buttonsLinks}` : `solid 1px ${theme.colors.grey[70]}`};
      color: ${selected ? theme.customized.colors.buttonsLinks : theme.colors.primaryBlack};
    `}
  `,
};

export default function Weight({ variant = `default`, selected = false, weightForQueryParams, displayWeight }) {
  const router = useRouter();
  const category = useDispensaryCategory();
  const subcategory = _.endsWith(router.route, ROUTES.SUBCATEGORY) ? router.query.subcategory : null;
  const { onSearchPage } = useUI();
  const { href, route } = usePaths({
    category,
    subcategory,
    specials: _.includes(router?.route, '/specials'),
    search: onSearchPage,
  });
  const { defaults, generateQueryParams } = useProductQueryParams();
  const amplitude = useAmplitude();

  const newQueryParamWeight = selected ? defaults.weight : weightForQueryParams;
  const newQuery = { ...generateQueryParams(`weight`, newQueryParamWeight) };
  delete newQuery.page;

  const trackEvent = () => {
    if (newQuery?.weight) {
      amplitude.log(`Weight Filter`, {
        description: `User clicks/taps on Weight Filter while shopping`,
        weightSelected: newQuery.weight,
      });
    }
  };

  return (
    <Link href={href} route={route} query={newQuery} scroll={false}>
      <Anchor selected={selected} onClick={trackEvent} variantStyles={VARIANT_STYLES_MAP[variant]}>
        {_.trimEnd(displayWeight, 'C')}
      </Anchor>
    </Link>
  );
}

const Anchor = styled.a`
  align-items: center;
  border-radius: ${({ theme }) => theme.customized.radius.sm};
  cursor: pointer;
  display: flex;
  font-size: 12px;
  font-weight: ${({ selected }) => (selected ? `bold` : `normal`)};
  height: 34px;
  justify-content: center;
  line-height: 2.5;
  margin-top: 1px;
  outline: none;

  &&& {
    ${({ variantStyles }) => variantStyles}
  }
`;
