import React from 'react';
import useTranslation from 'hooks/use-translation';
import { SidebarFiltersSection } from 'components/sidebar-filters';
import { useCategorySubcategoryFilter } from './use-category-subcategory-filter';
import { CategorySubcategories } from './types';
import { CategoriesList } from './categories-list';

export type CategorySubcategoryFilterProps = {
  categoriesSubcategories: CategorySubcategories[] | null;
};

export function CategorySubcategoryFilter({ categoriesSubcategories }: CategorySubcategoryFilterProps): JSX.Element {
  const { t } = useTranslation();
  const {
    selectedCategory,
    selectedSubcategories,
    handleClickCategory,
    handleClickSubcategory,
  } = useCategorySubcategoryFilter();

  return (
    <SidebarFiltersSection collapsible={false} heading={t('common.category_plural', 'Categories')}>
      <CategoriesList
        categoriesSubcategories={categoriesSubcategories}
        selectedCategory={selectedCategory}
        selectedSubcategories={selectedSubcategories}
        handleClickCategory={handleClickCategory}
        handleClickSubcategory={handleClickSubcategory}
      />
    </SidebarFiltersSection>
  );
}
