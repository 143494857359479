import React from 'react';
import useTranslation from 'hooks/use-translation';
import styled from 'styled-components';

export function OutOfStock(): JSX.Element {
  const { t } = useTranslation();

  return <OutofStock>{t('marketplace.out-of-stock', `Out of stock`)}</OutofStock>;
}

const OutofStock = styled.div`
  color: ${({ theme }) => theme.colors.grey[40]};
  font-size: 14px;
  font-weight: bold;
`;
