import React from 'react';
import { useAmplitude } from 'shared/hooks/use-amplitude';

import useProductQueryParams from 'hooks/use-product-query-params';
import useTranslation from 'hooks/use-translation';
import { potencyIsDefault } from 'utils/helpers/product';

import Section from 'components/sidebar-filters/section';
import { PotencySlider } from 'components/potency-slider';
import { useDebouncedCallback } from 'use-debounce/lib';

const debounceTime = 400;

export default function PotencyFilter({ category }) {
  const { defaults, queryParams, setQueryParams } = useProductQueryParams();
  const amplitude = useAmplitude();
  const { t } = useTranslation();

  function handleChangeTHC(_event, value) {
    if (!potencyIsDefault(value, category)) {
      amplitude.log(`Potency THC Filter`, {
        description: `User clicks/taps on Potency THC Filter while shopping`,
        potencyTHCSelected: value.join(', '),
      });
    }

    setQueryParams({
      potencythc: value,
      page: defaults.page,
    });
  }

  function handleChangeCBD(_event, value) {
    if (!potencyIsDefault(value, category)) {
      amplitude.log(`Potency CBD Filter`, {
        description: `User clicks/taps on Potency CBD Filter while shopping`,
        potencyCBDSelected: value.join(', '),
      });
    }

    setQueryParams({
      potencycbd: value,
      page: defaults.page,
    });
  }

  const [debouncedCBDUpdate] = useDebouncedCallback(handleChangeCBD, debounceTime);
  const [debouncedTHCUpdate] = useDebouncedCallback(handleChangeTHC, debounceTime);

  return (
    <Section addPaddingRight startsOpen heading={t('common.potency', 'Potency')}>
      <PotencySlider title='THC' onChange={debouncedTHCUpdate} value={queryParams?.potencythc} category={category} />
      <PotencySlider title='CBD' onChange={debouncedCBDUpdate} value={queryParams?.potencycbd} category={category} />
    </Section>
  );
}
