import React from 'react';
import _ from 'lodash';
import { useRouter } from 'next/router';

import { SidebarFiltersSection, SidebarFiltersLinkList, SidebarFiltersLinkListItem } from 'components/sidebar-filters';
import { CheckboxGroup } from 'components/core';
import useTranslation from 'hooks/use-translation';
import { useBrandsFilter } from './use-brands-filter';
import { Brands } from './brands-filter.types';

type BrandsFilterProps = {
  brands: Brands;
};

export default function BrandsFilter({ brands }: BrandsFilterProps): JSX.Element {
  const { t } = useTranslation();

  const { brandsFromQueryParams, defaults, handleBrandSelection, href, parsedBrands, query, route } = useBrandsFilter(
    brands
  );

  const { pathname } = useRouter();

  // on brands page from the UI store has a delay when changing from certain pages.
  // This caused the wrong component to display for a second or 2 on slow networks.
  const onBrandsPage = pathname.includes('brands');

  return (
    <SidebarFiltersSection startsOpen heading={t('common.brand_plural', 'Brands')}>
      <SidebarFiltersLinkList>
        {onBrandsPage ? (
          parsedBrands.map(({ key, label, value }) => (
            <SidebarFiltersLinkListItem
              href={`${href}/${value}`}
              key={key}
              label={label}
              queryParam='brands'
              route={`${route}/[brandId]`}
              selected={value === query.brandId}
              title={label}
              value={defaults.brands}
            />
          ))
        ) : (
          <CheckboxGroup
            options={parsedBrands}
            selectedOptions={brandsFromQueryParams}
            onSelect={(brand) => handleBrandSelection(_.kebabCase(brand))}
          />
        )}
      </SidebarFiltersLinkList>
    </SidebarFiltersSection>
  );
}
